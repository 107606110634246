<template>
    <div class="container" id="select-date">

        <div class="row">
            <div class="col">
                <div class="page-header">
                    <h1>
                        Oogstregistratie
                    </h1>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="tabs-holder">
                    <ul class="tabs">
                        <li v-for="year in years" @click="setActiveYear(year)" :class="{active: year === activeYear}" :key="year">
                            <a>{{ year }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div id="months">
                    <div v-for="(month, mindex) of data" :key="mindex" class="month widget-holder">
                        <table class="months-table">

                            <!-- Maand header -->
                            <thead class="styled-table-header">
                                <tr class="month-header">
                                    <th class="month-label">{{ dateHelper.presentMonth(month.month) }}</th>
                                    <th class="weekday-label">Maandag</th>
                                    <th class="weekday-label">Dinsdag</th>
                                    <th class="weekday-label">Woensdag</th>
                                    <th class="weekday-label">Donderdag</th>
                                    <th class="weekday-label">Vrijdag</th>
                                    <th class="weekday-label">Zaterdag</th>
                                    <th class="weekday-label">Zondag</th>
                                </tr>
                            </thead>

                            <!-- Week row -->
                            <tr class="week-row" v-for="(week, windex) of month.data" :key="windex">

                                <!-- Weeknummer label -->
                                <td class="week-number">
                                    {{ week[0].format('W') }}
                                </td>

                                <!-- Dagen -->
                                <td class="week-day" :class="{'today': day.isSame(new Date(), 'day'), 'different-month': day.format('M') != month.month}" v-for="(day, dindex) of week" :key="dindex" @click="$router.push('/registrations?date_string=' + day.format('YYYY-MM-DD'));">
                                    <span v-if="day.format('M') == month.month">{{ day.format('D') }}</span>
                                </td>
                            </tr>

                        </table>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>

import moment from 'moment';
import { useDateHelper } from '../../composables/dateHelper.js';

export default {

    data(){
        return {
            years: _.range((new Date()).getFullYear(), 2016, -1),
            activeYear: 2018,
            months: 0,
            data: [],
            dateHelper: useDateHelper()
        }
    },

    created() {
        this.setActiveYear(moment().format('YYYY'));
    },

    methods: {
        setActiveYear(year) {
            this.activeYear = parseInt(year);
            this.months = parseInt((this.activeYear == moment().format('YYYY') ? moment().format('M') : 12));
            this.data = [];

            for (let month = this.months; month >= 1; month -= 1) {
                let firstDayOfMonth = moment(this.activeYear + '-' + month + '-01', 'YYYY-M-DD');
                let lastDayOfMonth = moment(this.activeYear + '-' + month + '-' + firstDayOfMonth.daysInMonth(), 'YYYY-M-DD');
                let curMonth = {
                    month: month,
                    data: []
                };

                let i = 1;
                let currentDay = firstDayOfMonth.clone().startOf('isoWeek');

                while (firstDayOfMonth <= lastDayOfMonth.endOf('isoWeek')) {
                    let week = [];

                    for (let day=1; day<=7; day++) {
                        week.push(currentDay.clone());
                        currentDay.add(1, 'day');
                    }

                    curMonth.data.push(week);
                    firstDayOfMonth.add(1, 'week');
                }

                this.data.push(curMonth);
            }
        },
    }
};
</script>
