import {AbstractModel} from './abstract-model.js'
import Chest from './chest.js';
import Type from './type.js';
import Size from './size.js';
import Content from './content.js';

export default class HarvestProduct extends AbstractModel {

    getDefaults() {
        return {
            id: null,
            chest_id: null,
            content_id: null,
            size_id: null,
            type_id: null,
            image_url: null,

            chest: null,
            content: null,
            size: null,
            type: null
        }
    }

    getTransformers() {
        return {
            chest: Chest,
            size: Size,
            type: Type,
            content: Content
        }
    }

    getCcst()
    {
        return {
            chest_id: (this.chest_id ?? this.chestId),
            content_id: (this.content_id ?? this.contentId),
            size_id: (this.size_id ?? this.sizeId),
            type_id: (this.type_id ?? this.typeId)
        }
    }

    name()
    {
        let name = '';
        name += this.chest.name + ' ';
        name += this.size.translate('name') + ' ';
        name += this.type.translate('name') + ' ';
        name += this.content ? this.content.name : 'onbekend' + ' ';

        return name;
    }

    match(ccst)
    {
        return (
            ccst.chest_id === (this.chest_id ?? this.chestId)
            && ccst.content_id === (this.content_id ?? this.contentId)
            && ccst.size_id === (this.size_id ?? this.sizeId)
            && ccst.type_id === (this.type_id ?? this.typeId)
        );
    }

    // V2
    relations()
    {
        return {
            chest: Chest,
            content: Content,
            size: Size,
            type: Type,
        }
    }

    relationPlural()
    {
        return {
            chest: false,
            content: false,
            size: false,
            type: false,
        }
    }

    getMutators() {
        return {
        };
    }
}
