import {AbstractModel} from './abstract-model.js'

export default class Unit extends AbstractModel {

    getMutators()
    {
        return {
        }
    }

    relations() {
        return {
        }
    }

}
