<template>
    <div class="container" id="orders">
        <div class="row">
            <div class="col">
                <div class="page-header">
                    <h1>Orders Exact</h1>
                    <ul class="switch" style="margin-right:50px;">
                        <li :class="{active: openAll == false}" @click="openAll = false">
                            <Icon name="bars-3" />
                        </li>
                        <li :class="{active: openAll == true}" @click="openAll = true">
                            <Icon name="square-2-stack" />
                        </li>
                    </ul>
                    <div @click="syncExact = true" class="horizontal-fab-button-holder">
                        <fab-button icon="arrow-path" ></fab-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="tabs-holder">
                    <ul class="tabs">
                        <li class="active">
                            <a>Overzicht</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="styled-list">
            <div class="users-search-holder">
                <input class="form-control" type="text" placeholder="Orders zoeken" v-model="search">
            </div>

            <ListRow
                @click.native="switchOpenOrder(order.id)"
                @printPackagingList="openPrintPackagingListModal"
                @downloadPackagingList="downloadPackagingListModal"
                :openAll="openAll" :openOrder="openOrder" :key="order.id" :order="order"
                v-for="order of filteredOrders.filter(o =>
                    o.orderLines.count() > 0
                    && (
                        search === ''
                        || o.orderLines.filter(ol => (ol.article.name.toLowerCase().includes(search.toLowerCase()))).count() > 0
                        || o.customer.name.toLowerCase().includes(search.toLowerCase())
                    )
                ).sort((a, b) => a.picked_up_at > b.picked_up_at ? 1 : -1)"
            ></ListRow>
        </div>

        <modal v-if="syncExact" class="sync-order-modal" @save="syncOrder()" @close="syncExact = false">

            <span>
                <form @submit.prevent>
                    <div class="form-group">
                        <label for="order_id">Order bronsleutel</label>
                        <input id="order_id" style="width:100%;" v-model="orderId" type="text">
                    </div>
                </form>
            </span>
        </modal>

        <modal v-if="printPackagingListModal" @save="printPackagingList()" @close="printPackagingListModal = false">

            <template v-slot:header>Print order</template>

            <label class="styled-label">
                <span>Laatst geprint op </span>
                {{ printPackagingListModal.order.lastPrintedAt ? printPackagingListModal.order.lastPrintedAt.format('DD-MM-YYYY HH:mm') :  '-' }}
            </label>

            <label class="styled-label">
                <span>Aantal</span>
                <input type="number" step="1" focus class="styled-input" required v-model="printPackagingListModal.amount">
            </label>

        </modal>


    </div>
</template>

<script setup>
    import { ref, computed, watch, onBeforeMount, onBeforeUnmount } from 'vue';
    import ListRow from './ListRow.vue';
    import Order from '../../models/order.js';
    import OrderService from '@/services/http/order-service';
    import { useToast } from 'vue-toast-notification';

    const orders = ref([]);
    const openOrder = ref(null);
    const done = ref(0);
    const search = ref('');
    const syncExact = ref(false);
    const orderId = ref('');

    const syncOrder = function() {
        OrderService.syncOrderFromExact(orderId.value).then(response => {
            orderId.value = '';
            syncExact.value = false;

            clearInterval(this.interval);
            getData().then(response => {
                useToast().success('Order gesynchroniseerd');
            });
        })
    };

    const switchOpenOrder = function (id) {
        openOrder.value = openOrder.value == id ? null : id;
    };

    const filteredOrders = computed(() => orders.value.filter(order => order.done == false).sort(compare));
    const compare = (a, b) => ((a.picked_up_at === null || a.picked_up_at > b.picked_up_at) ? 1 : -1);

    const getData = function() {
        done.value = 0;

        //axios.get('orders?include=customer,orderLines.article&empty=1&orderBy=picked_up_at,desc&date=' + moment().format('YYYY-MM-DD') ).then(response => {
        return axios.get('orders/with-statuses').then(response => {
            orders.value = [];
            for (let orderData of response.data.data) {
                let order = new Order(orderData);
                orders.value.push(order);
            }
        });
    };

    const openAll = ref(localStorage.openAll ? JSON.parse(localStorage.openAll) : false);
    watch(openAll, (newValue) => {
        localStorage.openAll = newValue;
    });


    const interval = ref(null);
    const setInterval = function() {
        interval.value = setInterval(function () {
            getData();
        }.bind(this), 30000);
    }
    getData();


    // ----------------------------------------------------------------------------------------------------------------
    // Print packaging list
    // ----------------------------------------------------------------------------------------------------------------
    const downloadPackagingListModal = function(order) {
        window.open('/orders/'+order.id+'/download', '_blank');
    }

    const printPackagingListModal = ref(null);
    const openPrintPackagingListModal = function(order) {
        printPackagingListModal.value = {
            order: order,
            amount: 1
        }
    }

    const printPackagingList = function() {
        OrderService.printPackagingList(
            printPackagingListModal.value.order.id,
            printPackagingListModal.value.amount
        ).then(response => {
            printPackagingListModal.value = null;
            getData();
            useToast().success('Pakbon wordt geprint');
        }).catch(err => {
            useToast().error('Er is iets mis gegaan');
        });
    }


    onBeforeUnmount(() => {
        clearInterval(interval.value);
    });
</script>
