<template>
    <div class="container">

        <div class="row">
            <div class="col-12">
                <div class="page-header">
                    <h1>
                        Werklijst {{ user.name() }} - {{ activeYear }}
                    </h1>

                    <div class="save-and-cancel-buttons" v-if="authUser.can('work_lists') && ! payed">
                        <fab-button :class="{danger:editting}" @click.native="toggleEdit" :icon="! editting ? 'pencil' : 'x-mark'"></fab-button>
                        <fab-button class="success" @click.native="save()" v-if="editting" icon="check"></fab-button>
                    </div>
                    <fab-button
                        v-if="authUser.can('work_lists') && ! editting"
                        v-tooltip="(payed === false ? 'Zet deze week op \'Uitbetaald\'' : 'Zet deze week op \'Niet uitbetaald\'')"
                        :class="{'paid': (payed !== false)}"
                        @click.native="togglePayed()"
                        icon="currency-euro"
                    ></fab-button>
                    <ExportExcel
                        v-if="! editting"
                        v-tooltip="'Excel downloaden'"
                        :activeYear="activeYear"
                        :user="user"
                        :activeWeek="activeWeek.weekNr"
                        :payOutsData="payOutsData"
                    />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="week-select">
                    <label>
                        <span class="styled-label">
                            Weeknummer
                        </span>
                        <div class="select-day">
                            <a class="navigate-week" @click="changeWeek(-1)">
                                <icon name="arrow-left"></icon>
                            </a>
                            <select class="styled-select" @change="weekChanged()" v-model="activeWeek">
                                <option :value="week" v-for="(week, index) of weeks" :key="index">{{ week.weekNr }}</option>
                            </select>
                            <a class="navigate-week" :class="{disabled: maxWeek}" @click="() => maxWeek ? '' : changeWeek(1)">
                                <icon name="arrow-right"></icon>
                            </a>
                        </div>
                        <span class="payed" v-if="payed">Uitbetaald</span>
                    </label>
                </div>
            </div>
        </div>

        <WorkSessions
            ref="workSessionsComponent"
            :id="id"
            :activeWeek="activeWeek"
            :editting="editting && ! payed"
            :monday="monday"
        />

        <RadishLists
            ref="radishListComponent"
            :editting="editting && ! payed"
            :activeWeek="activeWeek"
            :activeYear="activeYear"
            :id="id"
        />

        <TaskHoursComp
            ref="taskHoursComponent"
            :editting="editting && ! payed"
            :activeWeek="activeWeek"
            :activeYear="activeYear"
            :id="id"

        />

        <ConfirmModal v-if="confirm.active" :body="confirm.body" @yes="togglePayed" @no="confirm.active = ! confirm.active" />
    </div>
</template>

<script setup>
    import ExportExcel from './ExportExcel.vue';
    import RadishLists from './RadishLists.vue';
    import WorkSessions from './WorkSessions.vue';
    import TaskHoursComp from './TaskHours.vue';
    import { useToast } from 'vue-toast-notification';


    import collect from 'collect.js';
    import User from '../../../models/user.js';
    import ConfirmModal from '../../../components/ConfirmModal.vue';
    import { useAuthStore } from '../../../stores/auth';
    import moment from 'moment';
    import { computed, ref } from 'vue';

    const props = defineProps(['id', 'activeYear', 'activeWeek']);
    const emits = defineEmits(['setBreadcrumbLabels']);


    const router = useRouter();

    /*
    |--------------------------------------------------------------------------
    | Authorized
    |--------------------------------------------------------------------------
    */
    const authUser = useAuthStore().user;
    import { useRouter } from 'vue-router';
    if (! authUser.can('work_lists') && authUser.id != props.id) {
        useRouter().push('/dashboard');
    }

    /*
    |--------------------------------------------------------------------------
    | Radish list
    |--------------------------------------------------------------------------
    */
    const radishListComponent = ref(null);
    const taskHoursComponent = ref(null);

    /*
    |--------------------------------------------------------------------------
    | Work sessions
    |--------------------------------------------------------------------------
    */

    const workSessionsComponent = ref(null);

    const editting = ref(false);
    const user = ref(new User());
    const activeWeek = ref(null);
    const update = ref(0);
    const taskHourData = ref({});
    const originalTaskHourData = ref({});
    const payOutsData = ref({});
    const payed = ref(false);
    const confirm = ref({body: '', active: false, id: null});

    const monday = computed(() => {
        return getDateOfMonday(true);
    });


    const togglePayed = function() {
        if (confirm.value.active !== true) {
            confirm.value.active = true
            confirm.value.body = "Weet je zeker dat je deze week op " + (payed.value === false ? '' : 'niet ') +"betaald wilt zetten?"
        } else if (payed.value === false) {
            axios.post('pay-outs', {
                from: activeWeek.value.from.format('YYYY-MM-DD'),
                till: activeWeek.value.till.format('YYYY-MM-DD'),
                weeknumber: activeWeek.value.from.format('W'),
                year: activeWeek.value.from.isoWeekYear(),
                user_id: user.value.id
            }).then(response => payed.value = response.data.data[0].id);

            confirm.value.active = false;
            useToast().success('Betaald');
        } else {
            axios.delete('pay-outs/' + payed.value);
            payed.value = false;
            confirm.value.active = false;
            useToast().success('Niet betaald');
        }
    };

    const toggleEdit = function() {
        workSessionsComponent.value.edit();

        if (! editting.value) {
            if (! payed.value) {
                originalTaskHourData.value = _.cloneDeep(taskHourData.value);
            }
        } else {
            if (! payed.value) {
                taskHourData.value = originalTaskHourData.value;
            }
        }
        editting.value = (! editting.value);
        if (editting.value) {
            radishListComponent.value.edit();
            taskHoursComponent.value.edit();
        } else {
            radishListComponent.value.reset();
            taskHoursComponent.value.reset();
        }

    };


    const getUser = function() {
        return axios.get('users?id=' + props.id + '&deactivated=1&include=avatars,documents,userType')
        .then(response => {
            user.value = new User();
            user.value.setAttributesFromResponse(response.data.data[0]);
        });
    };

    const save = function() {
        Promise.all([
            workSessionsComponent.value.update(),
            radishListComponent.value.update(),
            taskHoursComponent.value.saveTaskHours()
        ])
        .then(() => {
            useToast().success('Werklijst opgeslagen')
        });

        update.value++;
        editting.value = false;
    };


    const getDateOfMonday = function(momentjs = false) {

        if(momentjs) {
            return activeWeek.value.from.clone();
        }

        return activeWeek.value.from.clone().toDate();
    };


    const getPayOutsData = function(weekday, task){
        axios.get('pay-outs/temp/' + props.id + '/' + activeWeek.value.from.format('YYYY-MM-DD') + '/' + activeWeek.value.till.format('YYYY-MM-DD')).then(response => {
            payOutsData.value = response.data;
        });
    };

    const getPayOut = function(){
        axios.get('pay-outs?user_id=' + props.id + '&from=' + activeWeek.value.from.format('YYYY-MM-DD') + '&till=' + activeWeek.value.till.format('YYYY-MM-DD')).then(response => {
            payed.value = (response.data.data.length > 0 ? response.data.data[0].id : false);
        });
    };

    const maxWeek = computed(() => {
        if (activeWeek.value.from.isoWeekYear() < moment().isoWeekYear()) {
            return false;
        }
        const newWeekNr = parseInt(activeWeek.value.weekNr) + 1;
        return (weeks.value.find(w => w.weekNr == newWeekNr) === undefined);
    });

    const changeWeek = function(amount) {
        const newWeekNr = parseInt(activeWeek.value.weekNr) + amount;

        if (newWeekNr === 0) {
            const urlSplitted = window.location.pathname.split('/')
            urlSplitted[urlSplitted.length - 1] = parseInt(urlSplitted[urlSplitted.length - 1])-1;
            window.location.href = urlSplitted.join('/');
            return;
        }

        const newActiveWeek = weeks.value.find(w => w.weekNr == newWeekNr);

        if (! newActiveWeek) {
            const urlSplitted = window.location.pathname.split('/')
            urlSplitted[urlSplitted.length - 1] = parseInt(urlSplitted[urlSplitted.length - 1])+1;
            window.location.href = urlSplitted.join('/');
        }

        activeWeek.value = weeks.value.find(w => w.weekNr == newWeekNr);
        weekChanged();
    }

    const weekChanged = function() {
        editting.value = false;
        getAllData();
    }

    const getAllData = function()
    {

        getPayOut();
        getPayOutsData();
    };




    // Set weeks
    const weeks = ref([]);
    const setWeeks = function () {

        const date = moment('01-01-' + props.activeYear, 'DD-MM-YYYY').startOf('isoWeek');

        while (
            date.isoWeekYear() == props.activeYear
            || date.clone().endOf('isoWeek').year() == props.activeYear
        ) {

            weeks.value.push({
                weekNr: date.format('W'),
                from: date.clone(),
                till: date.clone().endOf('isoWeek')
            })

            date.add(1, 'week');
        }
    }

    setWeeks();
    const activeDate = (new Date()).getFullYear() != props.activeYear ? moment('01-01-' + props.activeYear, 'DD-MM-YYYY') : moment();
    weeks.value = weeks.value.reverse();
    activeWeek.value = weeks.value.find(wk => props.activeWeek ? props.activeWeek === wk.weekNr : wk.from.isSame(activeDate, 'week'));
    weeks.value = weeks.value.filter((week) => week.till.diff(moment(), 'week') < 1);

    // Get all data
    getAllData();
    getUser().then(() => {
        emits('breadcrumbs', [
            {to: `/users/work-lists`, label: 'Werklijsten'},
            {label: user.value.name()}
        ]);
    });
</script>
