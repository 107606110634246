import CheckAndCreate from '@/pages/Prices/CheckAndCreate.vue';
import Index from '../pages/Prices/Index.vue';
import Show from '../pages/Prices/Show.vue';

export default [
    {
        path: '/prices',
        component: Index,
        name: 'prices-index',
        meta: {permissions: ['prices']}
    },{
        path: '/prices/:id',
        component: Show,
        name: 'prices-show',
        meta: {permissions: ['prices']},
        props: true
    },{
        path: '/prices/create/:id/:cents',
        component: CheckAndCreate,
        name: 'prices-create',
        meta: {permissions: ['prices']},
        props: true
    }
];
