import { AbstractModel } from './abstract-model';
import Article from './article';
import User from './user';

export default class Price extends AbstractModel {

    getMutators()
    {
        return {
            createdAt: 'datetime'
        }
    }

    relations() {
        return {
            article: Article,
            user: User
        }
    }
    relationPlural()
    {
        return {
            article: false,
            user: false
        }
    }

}
