<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="page-header">
                        <h1>Verkoopprognose totalen</h1>
                    </div>
                    <div class="page-header-settings" style="border-bottom: 0; margin-bottom: 0;">
                        <div class="row">
                            <div class="col-6 col-md-4 col-lg-3">
                                <div class="form-control datepicker-styling">
                                    <span class="styled-label">
                                        Van
                                    </span>
                                    <DatePicker @input="dateChanged" v-model="f" />
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-3">
                                <div class="form-control datepicker-styling">
                                    <span class="styled-label">
                                        Tot
                                    </span>
                                    <DatePicker @input="dateChanged" v-model="t" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style="overflow-x: scroll;">
            <table class="styled-table sales-forecast-totals" :style="loader ? 'width: 100%' : `width: ${560 + (weeks.length * 120)}px;`">
                <thead v-if="! loader" class="styled-table-header">
                    <tr>
                        <th>
                            <div style="width:200px; text-align:left">
                                Radijs
                            </div>
                        </th>
                        <th v-for="(week, index) of weeks" :key="index" :class="{current: week.current.isSame(today, 'isoWeek')}">
                            <div class="week-number">
                                {{ week.current.isoWeek() }}
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody v-if="loader">
                    <tr>
                        <td colspan="100" style="text-align:center;">
                            <Loader />
                        </td>
                    </tr>
                </tbody>
                <tbody class="styled-table-body" v-else-if="data && types && articles">
                    <tr v-for="(type, index) of types" :key="index" >
                        <td style="background-color: white;">
                            <div style="width:200px; text-align:left">
                                {{ type.translate('name') }}
                            </div>
                        </td>
                        <td v-bind:style="{color: (week.current.isAfter(today, 'isoWeek') ? 'silver' : '')}" v-for="(week, index2) of weeks" :key="index2" :class="{current: week.current.isSame(today, 'isoWeek')}">
                            <div class="week-content" style="position:relative">
                                <span v-wbtooltip="() => getTooltip(week.current, type.id)" @click="goToSaleforecasts(type.id)">
                                    {{ (data[index2][type.id].amount) !== 0 ? (data[index2][type.id].amount) : '-' }}
                                </span>

                                <span v-if="data[index2][type.id].saleForecasts > 0" class="add-sale-forecast has-sale-forecast">
                                    {{ data[index2][type.id].saleForecasts > 0 ? data[index2][type.id].saleForecasts : '' }}
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style="border-top: 2px solid #555; background-color: white;">
                            <div style="width: 200px; font-weight:600;">
                                Totaal
                            </div>
                        </td>
                        <td style="font-weight:600; border-top: 2px solid #555;" v-bind:style="{color: (week.current.isAfter(today, 'isoWeek') ? 'silver' : '')}" v-for="(week, index) of weeks" :key="index" :class="{current: week.current.isSame(today, 'isoWeek')}">
                            <div class="week-content">
                                {{ data[index].total }}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</template>

<script setup>
    import moment from 'moment';
    import { ref } from 'vue';
    import typeService from '../../services/http/type-service';
    import orderService from '../../services/http/order-service';
    import saleForecastService from '../../services/http/sale-forecast-service';
    import articleService from '../../services/http/article-service';
    import { collect } from "collect.js";
    import useSaleForecastCalculations from './useSaleForecastCalculations';
    import { useRoute, useRouter } from 'vue-router'
    import Loader from '@/components/Loader.vue';

    const emit = defineEmits(['breadcrumbs']);

    const types = ref(false);

    const saleForecastModels = ref(collect());

    const data = ref(null);

    const articles = ref(collect());

    const nextWeek = moment().add(1, 'week').startOf('week');

    const loader = ref(false);

    const {
        f, t, today,
        weeks, setDates
    } = useSaleForecastCalculations();

    // ---------------------------------------------------------------------------------------------------------------------
    // Dates
    // ---------------------------------------------------------------------------------------------------------------------
    const dateChanged = function() {
        setDates();

        loader.value = true;

        data.value = null;
        Promise.all([getSaleHistoryTotals(), getSaleForecasts(), getGroupedSale()]).then(() => {
            setData();
        });
    };


    // ---------------------------------------------------------------------------------------------------------------------
    // Data
    // ---------------------------------------------------------------------------------------------------------------------
    const setData = function()
    {
        const newData = [];

        for (const week of weeks.value) {
            const weekWithLeadingZero = String(week.current.isoWeek()).padStart(2, '0');
            const newWeekData = {};
            const weekData = week.current.isBefore(nextWeek)
                ? groupedSale.value.get(weekWithLeadingZero)
                : saleHistory.value.get(weekWithLeadingZero);

            const amountAttribute = week.current.isBefore(nextWeek) ? 'amount' : 'average_sold';

            for (const type of types.value) {
                const typeData = weekData ? weekData.firstWhere('type_id', type.id) : null;

                const groupedSaleForecast = saleForecasts.value.first(sf => {return (
                    sf.year == week.current.isoWeekYear()
                    && sf.week == week.current.isoWeek()
                    && sf.type_id === type.id
                )});

                //console.log(amountAttribute, typeData, typeData ? Math.round(typeData[amountAttribute]) : 0)

                newWeekData[type.id] = {
                    amount: typeData ? Math.round(typeData[amountAttribute]) : 0,
                    saleForecasts: groupedSaleForecast ? groupedSaleForecast.amount : null
                };
            }

            newWeekData['total'] = weekData ? Math.round(weekData.sum(amountAttribute)) : collect();

            newData.push(newWeekData);
        }

        loader.value = false;
        data.value = newData;
    };

    // ---------------------------------------------------------------------------------------------------------------------
    // Sale past
    // ---------------------------------------------------------------------------------------------------------------------

    const groupedSale = ref(collect());
    const getGroupedSale = function() {
        if (moment(f.value).isAfter(nextWeek)) {
            groupedSale.value = collect();
            return;
        }

        const till = moment(t.value).isAfter(nextWeek) ? nextWeek : moment(t.value);

        return orderService.getGroupedSale(moment(f.value), till, [
            'week', 'type_id'
        ], ['amount'])
        .then((results) => {
            groupedSale.value = collect(results).groupBy('week')
        });
    }

    // ---------------------------------------------------------------------------------------------------------------------
    // Sale history (For future)
    // ---------------------------------------------------------------------------------------------------------------------

    const saleHistory = ref(collect());
    const getSaleHistoryTotals = function() {
        if (moment(t.value).isBefore(nextWeek)) {
            saleHistory.value = collect();
            return;
        }

        const from = moment(f.value).isBefore(nextWeek) ? nextWeek : moment(f.value);

        return orderService.getSaleHistory(from, moment(t.value), [
            'week', 'type_id'
        ], ['average_sold'])
        .then((results) => {
            saleHistory.value = collect(results).groupBy('week');
        });
    };

    const currentTooltip = ref({week: null, typeId: null, customerId: null, tooltip: null});
    const getTooltip = async function(date, typeId) {
        if (
            currentTooltip.value.week === date.isoWeek()
            && currentTooltip.value.typeId === typeId
        ) {
            return currentTooltip.value.tooltip;
        }

        currentTooltip.value = {week: date.isoWeek(), typeId: typeId, tooltip: null};

        return orderService.getSaleHistory(
            moment(date.clone().startOf('week')),
            moment(date.clone().endOf('week')), [
                'year', 'week', 'type_id'
            ],
            ['amount'],
            {type_id: typeId}
        ).then((results) => {
            const lastYear = date.clone().subtract(1, 'year');
            const twoYearAgo = date.clone().subtract(2, 'year');
            const threeYearAgo = date.clone().subtract(3, 'year');

            const lastYearResult = results.find(r => r.year == lastYear.isoWeekYear());
            const twoYearAgoResult = results.find(r => r.year == twoYearAgo.isoWeekYear());
            const threeYearAgoResult = results.find(r => r.year == threeYearAgo.isoWeekYear());

            return currentTooltip.value.tooltip =
                lastYear.isoWeekYear() + ': ' + (lastYearResult ? lastYearResult.amount : 0)
                + '<br/>' + twoYearAgo.isoWeekYear() +': ' + (twoYearAgoResult ? twoYearAgoResult.amount : 0)
                + '<br/>' + threeYearAgo.isoWeekYear() +': ' + (threeYearAgoResult ? threeYearAgoResult.amount : 0)
        });
    }

    // ---------------------------------------------------------------------------------------------------------------------
    // Saleforecasts
    // ---------------------------------------------------------------------------------------------------------------------

    const saleForecasts = ref(collect());
    const getSaleForecasts = function()  {

        return saleForecastService.getGroupedTotals(moment(f.value), moment(t.value)).then(saleForecastModels => {
            saleForecasts.value = collect(saleForecastModels);
        });
    };



    // ---------------------------------------------------------------------------------------------------------------------
    // Navigation
    // ---------------------------------------------------------------------------------------------------------------------
    const router = useRouter();
    const goToSaleforecasts = function(typeId) {
        router.push({
            path: '/sowing-planning/sales-forecast',
            query: {
                from: f.value,
                till: t.value,
                typeId: typeId,
                prognoseOnly: true
            }
        })
    };

    // ---------------------------------------------------------------------------------------------------------------------
    // Getters (sub)
    // ---------------------------------------------------------------------------------------------------------------------
    const getTypes = function() {
        return typeService.get().then(typeModels => {
            types.value = typeModels;
        });
    };

    const getArticles = function()
    {
        return articleService.get().then(articleModels => {
            articles.value = articleModels.sortBy('name')
        });
    };


    Promise.all([
        getArticles(),
        getTypes()
    ]).then(() => {
        dateChanged();
    });

    emit('breadcrumbs', [
        {to: `/sowing-planning`, label: 'Zaaiplanning'},
        {label: 'Verkoopprognose totalen'}
    ]);
</script>
