import moment from 'moment';
import { collect } from "collect.js";
import settingService from '../../services/http/setting-service';
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';

export default function UsePriceFunctions() {


    const formatNumberAsMoney = function(number) {
        return new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(number);
    }

    return {

        // Variables

        // Computed

        // Functions
        formatNumberAsMoney
    }
}
