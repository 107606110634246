<template>
    <Teleport to="body">
        <div style="background: rgba(0, 0, 0, .4); display: block; padding-top:20%" :class="{'no-fade': noFade}" class="modal show" tabindex="-1">
            <Loader color="#ffffff" />
        </div>
    </Teleport>
</template>

<script setup>
    import { onBeforeMount, onBeforeUnmount } from 'vue';
    import Loader from './Loader.vue';

    const props = defineProps(['noFade'])

    onBeforeMount(() => {
        document.body.classList.add("modal-open");
    })

    onBeforeUnmount(() => {
        document.body.classList.remove("modal-open");
    })
</script>
