<template>
    <div class="list-item order clickable">

        <div class="container">
            <div class="row">
                <div class="col-6 col-lg-4">
                    <div class="list-text-label">Klant</div>
                    <div class="list-text">{{ order.customer.name }}</div>
                </div>
                <div class="col-6 col-lg-3">
                    <div class="list-text-label">Laadtijd</div>
                    <div class="list-time-picker">
                        <div v-if="! editPickedUpAt">
                            {{ order.pickedUpAt ? order.pickedUpAt.format('HH:mm') : '-' }}
                            <a v-tooltip="'Laadtijd wijzigen'" class="edit-icon" @click.stop="toggleEditPickedUpAt()"><Icon name="pencil" type="solid" /></a>
                        </div>
                        <div v-else>
                            <timePicker class="edit-input" v-model="pickedUpAt"></timePicker>
                            <a class="edit-save" @click.stop="save()">
                                <Icon name="check" />
                            </a>
                            <a class="edit-cancel" @click.stop="toggleEditPickedUpAt()">
                                <Icon name="x-mark" />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-lg-3">
                    <div class="list-text-label">Status</div>
                    <div class="list-text">
                        <span v-if="order.done == true">
                            {{ order.presentStatus() }}
                        </span>
                        <span v-else-if="order.status === undefined && status === 'done' || order.status == 'done'">
                            {{ order.presentStatus() }}
                        </span>
                        <span v-else>
                            {{ order.status === undefined ? status : order.presentStatus() }}
                        </span>
                    </div>
                </div>
                <div class="col-6 col-lg-2">
                    <div class="list-icon-holder">
                        <fab-button
                            :class="{printed: order.lastPrintedAt !== null}"
                            @click.stop="emit('printPackagingList', order)"
                            v-tooltip="order.lastPrintedAt ? `Laatst geprint op: ${order.lastPrintedAt.format('DD-MM-YYYY HH:mm')}` : null"
                            icon="printer"
                            style="float:right; margin-top: 10px;"
                        />

                        <fab-button
                            @click.stop="emit('downloadPackagingList', order)"
                            icon="document"
                            style="float:right; margin-top: 10px;"
                        />
                    </div>
                </div>

            </div>

            <div class="row" v-if="openAll || openOrder == order.id">
                <div class="container">

                    <div class="row" style="margin-bottom: 30px;" v-if="order.description || palletLines.count() > 0">
                        <!-- Description -->
                        <div class="col" v-if="order.description">
                            <div class="list-text-label">Omschrijving</div>
                            <div class="list-text">{{ order.description }}</div>
                        </div>

                        <!-- Pallets -->
                        <div class="col" v-if="palletLines.count() > 0">
                            <div class="list-text-label">Pallets</div>
                            <div class="list-text">{{ palletLines.map(palletLine => (palletLine.amount + 'x ' +palletLine.article.name)).join(', ') }}</div>
                        </div>
                    </div>

                    <!-- Order lines -->
                    <div class="row">
                        <div class="list-text-label">Producten</div>
                        <div class="order-line" v-for="(orderLine, index) of nonPalletLines" :key="index">
                            <div class="row">
                                <div class="col-8">
                                    <div class="list-text">
                                        {{ orderLine.article.name }}
                                    </div>
                                </div>
                                <div class="col-2">
                                    <div class="list-text status">
                                        {{ orderLine.article.isHarvestable() ? orderLine.presentStatus() : '-' }}
                                    </div>
                                </div>
                                <div class="col-1">
                                    <div class="list-text icons">
                                        <Icon v-tooltip="'Verpakproduct'" v-if="orderLine.article.packaging == 1" name="cube" />
                                        <Icon v-tooltip="'Met dekvel'" v-if="orderLine.article.cover_sheet == 1" name="map" />
                                    </div>
                                </div>
                                <div class="col-1">
                                    <div class="list-text amount">
                                        {{ orderLine.amount }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <ConfirmModal v-if="confirm.active" :body="confirm.body" @yes="toggleDone" @no="confirm.active = ! confirm.active"></ConfirmModal>
    </div>


</template>

<script setup>
    import moment from 'moment';
    import ConfirmModal from './../../components/ConfirmModal.vue';
    import { ref, computed, watch } from 'vue';

    const props = defineProps(['order', 'openAll', 'openOrder']);
    const emit = defineEmits(['printPackagingList', 'downloadPackagingList']);

    const confirm = ref({body: '', active: false});
    const editPickedUpAt = ref(false);
    const oldPickedUpAt = ref(null);
    const pickedUpAt = ref(props.order.pickedUpAt ? props.order.pickedUpAt.format('HH:mm') : null);
    const status =  ref(props.order.status);
    const order = ref(props.order);

    const palletLines = computed(() => props.order.orderLines.filter(function(orderLine){
        return orderLine.article.isPallet();
    }));

    const nonPalletLines = computed(() => props.order.orderLines.filter(function(orderLine){
        return (! orderLine.article.isPallet() && ! orderLine.article.isStatie());
    }));

    const save = function() {
        if (pickedUpAt.value != oldPickedUpAt.value) {

            if (pickedUpAt.value === '' || ! pickedUpAt.value) {
                order.value.pickedUpAt = null;
            } else {
                order.value.pickedUpAt = moment(pickedUpAt.value, 'HH:mm');
            }

            axios.put('orders/' + order.value.id, {
                picked_up_at: (order.value.pickedUpAt ? order.value.pickedUpAt.format('YYYY-MM-DD HH:mm:ss') : null)
            });
        }

        oldPickedUpAt.value = props.pickedUpAt;
        editPickedUpAt.value = false;
    };

    const toggleDone = function() {
        confirm.value.active = false;
        order.value.done = ! order.value.done;

        axios.put('orders/' + order.value.id, {
            done: order.value.done
        });
    };

    const toggleEditPickedUpAt = function() {
        if (editPickedUpAt.value) {
            pickedUpAt.value = oldPickedUpAt.value;
        } else {
            oldPickedUpAt.value = pickedUpAt.value;
        }

        editPickedUpAt.value = ! editPickedUpAt.value;
    }
</script>
