<template>
    <div id="prices">
        <div class="container">

            <!-- HEADER & FILTERS -->
            <div class="row">
                <div class="col">
                    <div class="page-header">
                        <h1>Prijzen</h1>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <input class="styled-input" type="text" placeholder="Zoeken" v-model="search">
                </div>
            </div>

            <!-- ARTICLES OVERVIEW -->
            <div class="prices" v-for="group in groupedArticles">
                <div class="article-group">
                    <h2 class="article-group-header">
                        {{ group.name }} {{ group.name === 'Bulk' ? '(per 1000g)' : '' }}
                    </h2>
                    <table class="styled-table">
                        <thead class="styled-table-header">
                            <tr>
                                <th>
                                    Artikelnummer
                                </th>
                                <th style="text-align: left;">
                                    Naam
                                </th>
                                <th>
                                    Prijs
                                </th>
                                <th>
                                    Laatste wijziging
                                </th>
                                <th>
                                    &nbsp;
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="styled-table-row article" v-for="article of group.data">
                                <td>
                                    {{ article.externalCode }}
                                </td>
                                <td style="text-align: left;">
                                    {{ article.name }}
                                </td>
                                <td>
                                    {{ formatNumberAsMoney(article.cents/100) }}
                                </td>
                                <td>
                                    {{ article.price ? article.price.createdAt.format('DD-MM-YYYY') : '-' }}
                                </td>
                                <td class="styled-table-row-icons">
                                    <a v-tooltip="'Logboek'" class="styled-link" @click="$router.push(`/prices/${article.id}`)">
                                        <Icon name="list-bullet" />
                                    </a>
                                    <a v-tooltip="'Prijs wijzigen'" @click="createPriceModal = article">
                                        <Icon name="pencil" />
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>

    </div>

    <CreateModal
        v-if="createPriceModal"

        @close="createPriceModal = null"
        @save="createPrice"

        :article="createPriceModal"
    />

</template>

<script setup>
    import { ref, computed } from 'vue';
    import articleService from '@/services/http/article-service';
    import collect from 'collect.js';
    import CreateModal from './CreateModal.vue';
    import UsePriceFunctions from './UsePriceFunctions';
    import { useRouter } from 'vue-router';

    const emit = defineEmits(['breadcrumbs']);

    const { formatNumberAsMoney } = UsePriceFunctions()
    const router = useRouter();

    const search = ref('');
    const articles = ref(collect());

    const createPriceModal = ref(null);

    const headers = [
        'Radijs',
        'Mini\'s',
        'Bulk',
        'Fust',
        'Diversen'
    ];

    /*
    |--------------------------------------------------------------------------
    | Create price
    |--------------------------------------------------------------------------
    */
    const createPrice = function(cents) {
        cents = cents * (createPriceModal.value.unit.name === 'Gram' ? 0.001 : 1);

        router.push(`/prices/create/${createPriceModal.value.id}/${cents}`);
    }

    /*
    |--------------------------------------------------------------------------
    | Parse article (groups)
    |--------------------------------------------------------------------------
    */
    const groupedArticles = computed(() => {

        const groups = [];

        const filteredArticles = search.value
            ? articles.value.filter(a => (
                a.name.toLowerCase().includes(search.value.toLowerCase())
                || a.externalCode.toLowerCase().includes(search.value.toLowerCase())
            ))
            : articles.value;

        for (const header of headers) {
            const group = {name: header, data: collect()}

            if (header === 'Radijs') {
                group.data = filteredArticles.filter(a =>  (
                    ['Bos', 'Schaal'].includes(a.unit.name)
                    && a.size && a.size.name !== 'mini'
                ));
            } else if (header === 'Mini\'s') {
                group.data = filteredArticles.filter(a =>  (
                    ['Bos', 'Schaal'].includes(a.unit.name)
                    && a.size && a.size.name === 'mini'
                ));
            } else if (header === 'Bulk') {
                group.data = filteredArticles.filter(a =>  (a.unit.name === 'Gram'));
            } else if (header === 'Fust') {
                group.data = filteredArticles.filter(a => (a.articleGroup.name === 'Fust'));
            } else if (header === 'Diversen') {
                group.data = filteredArticles.filter(a => ['Verpakkingsmaterialen', 'Fust Toevoeging'].includes(a.articleGroup.name));
            }

            if (group.data.count() > 0) {
                groups.push(group);
            }
        }

        return groups;
    });


    /*
    |--------------------------------------------------------------------------
    | Get/set articles
    |--------------------------------------------------------------------------
    */
    const getArticles = function() {
        return articleService.get({
            filters: ['prices_articles'],
            include: ['unit', 'size', 'articleGroup', 'type', 'price']
        }).then(articleModels => {
            articles.value = articleModels.sort(sortArticles);
        });
    }
    getArticles();

    const sortArticles = function(a, b) {
        if (a.packagingId !== b.packagingId) {
            return (a.packagingId > b.packagingId ? 1 : -1)
        }

        if (a.unit && b.unit && a.unit.name !== b.unit.name) {
            if (a.unit === 'bos') return 1;
            if (b.unit === 'bos') return -1;

            return (a.unit.name > b.unit.name ? 1 : -1);
        }

        if (a.type && b.type && a.type.sequence !== b.type.sequence) {
            return (a.type.sequence > b.type.sequence ? 1 : -1);
        }

        return (a.name > b.name ? 1 : -1)
    }


    /*
    |--------------------------------------------------------------------------
    | Other
    |--------------------------------------------------------------------------
    */

</script>
