<template>
    <VueDatePicker
        @update:model-value="valueChanged"
        :week-numbers="{ type: 'iso' }"
        auto-apply
        v-model="date"
        :enable-time-picker="false"
        :format="format"
        :text-input="{
            selectOnFocus: true,
            format: 'dd-MM-yyyy',
        }"
        :min-date="min"
        :max-date="max"
        :clearable="clearable"
    />
</template>

<script setup>
    import { ref, watch } from 'vue';
    import moment from 'moment'

    const props = defineProps({
        modelValue: [String, moment],
        max: Number,
        min: Number,
        clearable: {
            default: true,
            type: Boolean
        },
        weekNumbers: Boolean,
        beforeUpdate: Function
    });
    const emit = defineEmits(['update:modelValue', 'input']);

    const date = ref(props.modelValue);

    watch(() => props.modelValue, (newValue, oldValue) => {
        date.value = newValue;
    });

    const valueChanged = function(modelData) {
        let value = (
            props.modelValue instanceof moment
            ? moment(modelData)
            : moment(modelData).format('YYYY-MM-DD')
        )

        if (props.beforeUpdate && value) {
            value = props.beforeUpdate(value);
        }

        emit('update:modelValue', value);
        emit('input', value);
    }

    const format = function(date) {
        return props.weekNumbers === false ? moment(date).format('DD-MM-YYYY') : moment(date).format('[W]W | DD-MM-YYYY');
    }
</script>
