import moment from 'moment';
import { collect } from "collect.js";
import settingService from '../../services/http/setting-service';
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';

export default function useSaleForecastCalculations() {

    const query = useRoute().query;

    const f = ref('from' in query ? query.from : moment().subtract(1, 'month').startOf('isoweek').format('YYYY-MM-DD'));
    const t = ref('till' in query ? query.till : moment().add(3, 'month').endOf('isoweek').format('YYYY-MM-DD'));

    const from = ref(null);
    const till = ref(null);

    const lastYearFrom = ref(null);
    const lastYearTill = ref(null);

    const twoYearFrom = ref(null);
    const twoYearTill = ref(null);

    const threeYearFrom = ref(null);
    const threeYearTill = ref(null);

    const today = ref(moment());

    const weeks = computed(() => {
        let weeks = [];

        if (from.value) {
            for (let weekNr = 0; weekNr <= till.value.diff(from.value, 'week'); weekNr++) {
                let week = {
                    current: from.value.clone().add(weekNr, 'week'),
                    prev: lastYearFrom.value.clone().add(weekNr, 'week'),
                    prevTwo: twoYearFrom.value.clone().add(weekNr, 'week'),
                    prevThree: threeYearFrom.value.clone().add(weekNr, 'week')
                };
                weeks.push(week);
            }
        }

        return weeks;
    });

    const betweens = computed(() => {
        return [{
            from: from.value.format('YYYY-MM-DD HH:mm:ss'),
            till: till.value.format('YYYY-MM-DD HH:mm:ss')
        }, {
            from: lastYearFrom.value.format('YYYY-MM-DD HH:mm:ss'),
            till: lastYearTill.value.format('YYYY-MM-DD HH:mm:ss')
        }, {
            from: twoYearFrom.value.format('YYYY-MM-DD HH:mm:ss'),
            till: twoYearTill.value.format('YYYY-MM-DD HH:mm:ss')
        }, {
            from: threeYearFrom.value.format('YYYY-MM-DD HH:mm:ss'),
            till: threeYearTill.value.format('YYYY-MM-DD HH:mm:ss')
        }];
    });

    const setDates = function(fromDate = null, tillDate = null)
    {
        if (! fromDate) {
            from.value = moment(f.value).startOf('isoWeek');
        }

        if (! tillDate) {
            till.value = moment(t.value).endOf('isoWeek');
        }

        lastYearFrom.value = moment(`06-${from.value.isoWeekYear()-1}`, 'MM-YYYY').isoWeek(from.value.isoWeek()).startOf('isoWeek');
        lastYearTill.value = moment(`06-${till.value.isoWeekYear()-1}`, 'MM-YYYY').isoWeek(till.value.isoWeek()).endOf('isoWeek');

        twoYearFrom.value = moment(`06-${from.value.isoWeekYear()-2}`, 'MM-YYYY').isoWeek(from.value.isoWeek()).startOf('isoWeek');
        twoYearTill.value = moment(`06-${till.value.isoWeekYear()-2}`, 'MM-YYYY').isoWeek(till.value.isoWeek()).endOf('isoWeek');

        threeYearFrom.value = moment(`06-${from.value.isoWeekYear()-3}`, 'MM-YYYY').isoWeek(from.value.isoWeek()).startOf('isoWeek');
        threeYearTill.value = moment(`06-${till.value.isoWeekYear()-3}`, 'MM-YYYY').isoWeek(till.value.isoWeek()).endOf('isoWeek');
    }

    const customFormatter = function(date) {
        date = moment(date);

        if (date.isoWeekYear() !== moment().isoWeekYear()) {
            return date.isoWeekYear() + ' - Week ' + date.isoWeek();
        }

        return 'Week ' + date.isoWeek();
    }

    // ---------------------------------------------------------------------------------------------------------------------
    // Gains
    // ---------------------------------------------------------------------------------------------------------------------

    const calculateGainsComparedToLastYear = function(bunches, lastYearBunches) {
        if (bunches === 0 || lastYearBunches === 0) {
            return null;
        }

        return (-((1- (bunches/lastYearBunches))*100));
    }


    return {

        // Variables
        f, t, from, till, lastYearFrom, lastYearTill, twoYearFrom, twoYearTill,
        threeYearFrom, threeYearTill, today,

        // Computed
        weeks, betweens,

        // Functions
        setDates, customFormatter, calculateGainsComparedToLastYear
    }
}
