import {AbstractModel} from './abstract-model.js';
import Customer from './customer.js';
import OrderLine from './order-line.js'
import moment from 'moment';

export default class Order extends AbstractModel
{
    getDefaults() {
        return {
            id: null,
            external_id: null,
            customer_id: null,
            zero_order_line: null,
            done: null,
            picked_up_at: moment(),
            customer: null,
            orderLines: [],
            description: null,
            status: null
        }
    }

    isDone()
    {
        for (let orderLine of this.orderLines) {
            if (! orderLine.done()) return false;
        }

        return true;
    }

    getTransformers() {
        return {
            customer: Customer,
            orderLines: OrderLine
        }
    }

    sortBy() {
        return {
            orderLines: function(a, b) {
                return (b.article.isHarvestable() ? 1 : -1);
            }
        }
    }

    bunches()
    {
        return _.sumBy(this.orderLines, ol => ol.bunches());
    }

    bunchesDone()
    {
        return _.sumBy(this.orderLines, ol => ol.bunchesDone());
    }

    presentStatus() {
        return {
            "inStock": "Voorraad",
            "transporting": "Transport",
            "harvesting": "Oogsten",
            "sorted": "Ingedeeld",
            "new": "Nieuw",
            "unknown": "Onbekend",
            "in_progress": "In behandeling",
            "done": "Gereed",
            "finished": "Afgerond"
        }[this.status]
    }

    // V2
    getMutators() {
        return {
            'pickedUpAt': 'datetime',
            'lastPrintedAt': 'datetime'
        };
    }

    relations()
    {
        return {
            customer: Customer,
            orderLines: OrderLine
        }
    }

    relationPlural()
    {
        return {
            customer: false,
            orderLines: true
        }
    }
}
