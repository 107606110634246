<template>
    <div>

        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="page-header">
                        <h1>Benodigd M2</h1>
                    </div>
                    <div class="page-header-settings" style="border: 0; margin-bottom: 0;">
                        <div class="row">
                            <div class="col-6 col-md-4 col-lg-3">
                                <div class="form-control datepicker-styling">
                                    <span class="styled-label">
                                        Van
                                    </span>
                                    <DatePicker @input="dateChanged()" v-model="f" />
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-3">
                                <div class="form-control datepicker-styling">
                                    <span class="styled-label">
                                        Tot
                                    </span>
                                    <DatePicker @input="dateChanged()" v-model="t" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style="overflow: scroll; max-height: calc(100vh - 334px); margin-bottom: -80px;">
            <table class="styled-table sow-planning" :style="`width: ${560 + (showWeeks.length * 120)}px;`">
                <thead class="styled-table-header">
                    <tr>
                        <th>
                            <div style="">
                                Radijs
                            </div>
                        </th>
                        <th v-for="(week, index) of showWeeks" :key="index" :class="{current: week.isSame(today, 'isoWeek')}">
                            <div class="week-number">
                                {{ week.isoWeek() }}
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody class="styled-table-body" v-if="data && types && articles && tubersPerType">
                    <tr v-for="(type, index) of types" :key="index" style="">
                        <td>
                            <div class="type-column">
                                {{ type.translate('name') }}
                            </div>
                        </td>
                        <td v-for="(week, index) of showWeeks" :class="{current: week.isSame(today, 'isoWeek')}">
                            <div class="week-content">
                                <div><b>{{ Math.round(data[index][type.id]) }}</b></div>
                                <div class="breed-per-type" v-for="(child, index3) of type.children" :key="index3">
                                    {{ child.breedPerTypes.firstWhere('weekNumber', week.isoWeek()).name() }}
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style="border-top: 2px solid #555;">
                            <div style="font-weight:600">
                                Totaal
                            </div>
                        </td>
                        <td style="font-weight:600; border-top: 2px solid #555;" v-for="(week, index) of showWeeks" :class="{current: week.isSame(today, 'isoWeek')}">
                            <div class="week-content">
                                {{ Math.round(data[index].total) }}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>

</template>

<script setup>
    import moment from 'moment';
    import { ref, computed } from 'vue';
    import typeService from '../../services/http/type-service';
    import saleIgnoreService from '../../services/http/sale-ignore-service';
    import { collect } from "collect.js";
    import tubersPerArticleService from '../../services/http/tubers-per-article-service';
    import useSaleForecastCalculations from './useSaleForecastCalculations';


    import seedsPerSquaredMeterService from '../../services/http/seeds-per-squared-meter-service';
    import cultivationCalendarDayService from '../../services/http/cultivation-calendar-day-service';

    const emit = defineEmits(['breadcrumbs']);

    const saleIgnoresPerType = ref(false);
    const tubersPerType = ref(false);
    const types = ref(false);

    const data = ref(false);

    const articles = ref(collect());
    const cultivationCalendarDays = ref(false);
    const seedsPerSquaredMeters = ref(false);

    const {
        f, t, from, till,
        today, weeks, setDates, betweens
    } = useSaleForecastCalculations();

    const showWeeks = computed(() => {
        let weeks = [];
        let tillDate = moment(t.value);
        let fromDate = moment(f.value);

        if (from.value) {
            for (let weekNr = 0; weekNr <= tillDate.diff(from, 'week'); weekNr++) {
                weeks.push(fromDate.clone().add(weekNr, 'week'));
            }
        }

        return weeks;
    })

    const seedPerSquaredMetersGrouped = computed(() => {
        return seedsPerSquaredMeters.value.groupBy('typeId');
    });


    // ---------------------------------------------------------------------------------------------------------------------
    // Dates
    // ---------------------------------------------------------------------------------------------------------------------

    const dateChanged = function(){
        from.value = moment(f.value).startOf('isoWeek');
        till.value = moment(t.value).endOf('isoWeek');

        from.value.add(cultivationCalendarDays.value.first(ccd => (
            ccd.month === from.value.month()+1,
            ccd.dayOfMonth === from.value.day()
        )).days, 'day').startOf('isoWeek');

        till.value.add(cultivationCalendarDays.value.first(ccd => (
            ccd.month === till.value.month()+1,
            ccd.dayOfMonth === till.value.date()
        )).days, 'day').endOf('isoWeek');

        setDates(from.value, till.value);
        let saleIgnoresPerType = getSaleIgnoresPerType();
        let tubersPerType = getTubersPerType();

        data.value = false;

        Promise.all([saleIgnoresPerType, tubersPerType]).then(() => {
            setData();
        });

    }

    // ---------------------------------------------------------------------------------------------------------------------
    // Data
    // ---------------------------------------------------------------------------------------------------------------------
    const setData = function()
    {
        const newData = [];

        const gains = {};

        for (const type of types.value) {
            gains[type.id] = [];
        }

        for (const week of weeks.value) {
            const curWeekTubersPerType = getWeeklyTubers(tubersPerType.value, week.current);
            const prevWeekTubersPerType = getWeeklyTubers(tubersPerType.value, week.prev);

            const curSaleIgnoresPerType = getWeeklyTubers(saleIgnoresPerType.value, week.current);
            const prevSaleIgnoresPerType = getWeeklyTubers(saleIgnoresPerType.value, week.prev);

            const weekData = {total: 0};
            for (const type of types.value) {

                if (week.current.isSameOrBefore(today.value, 'isoWeek')) {
                    weekData[type.id] = getTubers(curWeekTubersPerType, type) - getTubers(curSaleIgnoresPerType, type);
                } else {
                    weekData[type.id] = Math.round(
                        getTubers(prevWeekTubersPerType, type)
                        -
                        getTubers(prevSaleIgnoresPerType, type)
                    )
                }
                weekData.total += weekData[type.id];
            }

            weekData['cultivationCalendarDays'] = cultivationCalendarDays.value.filter(ccd => (ccd.harvestDate.isoWeek() === week.current.isoWeek())).count();
            weekData['week'] = `${week.current.isoWeekYear()}-${week.current.isoWeek()}`;

            newData.push(weekData);

        }

        data.value = [];
        for (let week of showWeeks.value) {
            const weekData = {total: 0};
            for (const type of types.value) {
                weekData[type.id] = 0;
            }
            const current = week.clone().startOf('isoWeek');

            for (let day = 1; day <= 7; day++) {
                const cultivationCalendarDay = cultivationCalendarDays.value.first(ccd => (
                    ccd.sowingDate.month() === current.month()
                    && ccd.sowingDate.date() === current.date()
                ));

                const currentHarvestDate = current.clone().add(cultivationCalendarDay.days, 'day');

                const forecastData = newData.find(d => {
                    return d.week === `${currentHarvestDate.isoWeekYear()}-${currentHarvestDate.isoWeek()}`
                });

                for (const type of types.value) {
                    // weekData[type.id] += forecastData[type.id]/forecastData['cultivationCalendarDays'];
                    // Bovenstaande
                    if (forecastData) {
                        weekData[type.id] += forecastData[type.id]/forecastData['cultivationCalendarDays'];
                    } else {
                        weekData[type.id] += 0;
                    }
                    // stond hiertussen
                }

                current.add(1, 'day')
            }


            for (const type of types.value) {
                if (weekData[type.id] !== 0) {
                    if (seedPerSquaredMetersGrouped.value.get(type.id).firstWhere('weekNumber', current.isoWeek()).seeds === 0) {
                        weekData[type.id] = 0
                    } else {
                        weekData[type.id] = (weekData[type.id]/seedPerSquaredMetersGrouped.value.get(type.id).firstWhere('weekNumber', current.isoWeek()).seeds);
                    }
                }
                weekData.total += weekData[type.id];

            }

            data.value.push(weekData);
        }
    };

    const getWeeklyTubers = function(objects, week) {
        return objects.filter(o => o.week == week.format('YYYYWW'));
    };

    const getTubers = function(objects, type) {
        let object = objects
            .find(o => o.type_id === type.id);

        return object ? object.amount : 0;
    };

    // ---------------------------------------------------------------------------------------------------------------------
    // Other
    // ---------------------------------------------------------------------------------------------------------------------

    const getTypes = function() {
        return typeService.get({include: ['children.breedPerTypes.breed', 'children.breedPerTypes.fraction']}).then(typeModels => {
            types.value = typeModels;
        });
    };

    const getSaleIgnoresPerType = function()  {
        saleIgnoresPerType.value = false;
        return saleIgnoreService.getSaleIgnoresPerType(betweens.value).then((saleIgnoresPerTypeModels) => {
            saleIgnoresPerType.value = saleIgnoresPerTypeModels;
        });
    };

    const getTubersPerType = function() {
        tubersPerType.value = false;
        return tubersPerArticleService.getTubersPerType(betweens.value).then((tubersPerTypeModels) => {
            tubersPerType.value = tubersPerTypeModels;
        });
    };

    const getCultivationCalendarDays = function() {
        return cultivationCalendarDayService.get().then(cultivationCalendarDaysModels => {
            cultivationCalendarDays.value = cultivationCalendarDaysModels;
        });
    };

    const getSeedsPerSquaredMeters = function(){
        return seedsPerSquaredMeterService.get().then(seedsPerSquaredMetersModels => {
            seedsPerSquaredMeters.value = seedsPerSquaredMetersModels;
        });
    };

    Promise.all([
        getTypes(),
        getCultivationCalendarDays(),
        getSeedsPerSquaredMeters(),
    ]).then(() => {
        dateChanged();
    });

    emit('breadcrumbs', [
        {to: `/sowing-planning`, label: 'Zaaiplanning'},
        {label: 'Benodigd M2'}
    ]);
</script>
