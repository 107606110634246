import Price from "../../models/price";
import { AbstractHttpService } from "./abstract-http-service";

class PriceService extends AbstractHttpService {

    endpoint() {
        return 'prices';
    }

    createModel(data) {
        return new Price(data);
    }

    storeMany(payload, parameters = {}) {
        let url = this.makeUrl(parameters, 'store-many');

        return axios['post'](url, payload);
    }
}

export default new PriceService;
