<template>
    <modal @save="emit('save', parseInt(cents))">
        <template v-slot:header>
            <span>{{ props.article.name }}</span>
        </template>

        <label class="styled-label">
            <span>Huidige prijs</span>
            <div class="styled-result">{{ formatNumberAsMoney(props.article.cents/100) }}</div>
        </label>

        <label class="styled-label new-price-input">
            <span>Nieuwe prijs (in centen)</span>
            <input
                class="styled-input"
                type="number"
                onkeydown="if(['.', ',','-','+','e','E'].includes(event.key)){event.preventDefault();}"
                v-model="cents"
            >
            <div class="new-price-presenter" style="top: 42px">
                {{ formatNumberAsMoney(cents/100) }}
            </div>
        </label>
    </modal>
</template>

<script setup>
    import Modal from '@/components/Modal.vue';
    import UsePriceFunctions from './UsePriceFunctions';
    import { ref } from 'vue'

    const props = defineProps(['article'])
    const emit = defineEmits(['save'])

    const { formatNumberAsMoney } = UsePriceFunctions()

    const cents = ref(props.article.cents);

</script>
