import Order from "../../models/order";
import { AbstractHttpService } from "./abstract-http-service";
import moment from 'moment';
import collect from 'collect.js';

class OrderService extends AbstractHttpService {

    endpoint() {
        return 'orders';
    }

    createModel(data) {
        return new Order(data);
    }

    printPackagingList(orderId, amount) {
        return this.sendGetRequest(`${orderId}/print/${amount}`, {});
    }

    getCustomerInsightsSold(parameters) {
        return this.sendGetRequest('customer-insights-sold', parameters);
    }

    getCustomerInsightsSoldPerArticle(parameters) {
        return this.sendGetRequest('customer-insights-sold-per-article', parameters);
    }

    getCustomerInsightsGrowth(parameters) {
        return this.sendGetRequest('customer-insights-growth', parameters);
    }

    getCustomerInsightsPrice(parameters) {
        return this.sendGetRequest('customer-insights-price', parameters);
    }

    getCustomerInsightsPricePerPeriod(parameters) {
        return this.sendGetRequest('customer-insights-price-per-period', parameters);
    }

    getArticleInsightsGrowth(parameters) {
        return this.sendGetRequest('article-insights-growth', parameters);
    }

    getSingleArticleInsightsGrowth(parameters) {
        return this.sendGetRequest('single-article-insights-growth', parameters);
    }

    getSaleHistory(from, till, groupBy = [], select = [], where = []) {
        let url = this.makeUrl({filters: {
            from: from.clone().format('YYYY-MM-DD'),
            till: till.clone().format('YYYY-MM-DD'),
            groupBy: groupBy,
            select: select,
            where: where
        }}, `sale-history`);

        return axios.get(url).then(data => {
            return data.data;
        });
    }

    getGroupedSale(from, till, groupBy = [], select = [], where = []) {
        let url = this.makeUrl({filters: {
            from: from.clone().format('YYYY-MM-DD'),
            till: till.clone().format('YYYY-MM-DD'),
            groupBy: groupBy,
            select: select,
            where: where
        }}, `grouped-sales`);

        return axios.get(url).then(data => {
            return data.data;
        });
    }

    syncOrderFromExact(id) {
        let url = this.makeUrl({}, id + '/sync-order-from-exact');

        return axios.get(url).then(data => {
            return data.data;
        });
    }
}

export default new OrderService;
