<template>
    <div class="container" id="select-date">

        <div class="row">
            <div class="col">
                <div class="page-header">
                    <h1>Dagoogst</h1>
                    <fab-button @click.native="createEditDayHarvestKilo = {
                        date: date
                    }" icon="plus"></fab-button>
                    <ul class="switch" style="margin-right:50px;">
                        <li :class="{active: openAll == false}" @click="openAll = false">
                            <Icon name="bars-3" />
                        </li>
                        <li :class="{active: openAll == true}" @click="openAll = true">
                            <Icon name="square-2-stack" />
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="page-header-settings">
            <div class="row">
                <div class="col-6 col-md-4 col-lg-3 datepicker-styling">
                    <span class="styled-label">Van</span>
                    <DatePicker @input="getData()" :clearable="false" v-model="date" />
                </div>
                <div class="col-6 col-md-4 col-lg-3">
                    <span class="styled-label">Type</span>
                    <MultiSelect
                        v-model="typeIds"
                        :options="[
                            ...filterTypes.map(t => ({
                                label: t.translate('name'),
                                value: t.id
                            })).all(),
                            {label: 'Overig', value: 'other'}
                        ]"
                        placeholder="Kies een type"
                    ></MultiSelect>
                </div>
            </div>
        </div>

        <div class="styled-list">

            <div
                class="list-item clickable"
                v-for="ccst of ccsts.filter(ccst =>
                    typeIds.length <= 0 || typeIds.includes(ccst.type_id)
                    || typeIds.includes('other') && ! filterTypes.pluck('id').contains(ccst.type_id)
                )"
                :class="{
                    'day-harvest-shortage': ccst.dayHarvestRegistrations < ccst.dayHarvestRegistrationsNeeded,
                    'day-harvest-too-many': ccst.dayHarvestRegistrations > ccst.dayHarvestRegistrationsNeeded
                }"
            >
                <div class="container">
                    <div class="row day-harvest-header" @click="opened = (opened === ccst ? null : ccst)">
                        <div class="col-1">
                            <img style="width: 100%" :src="ccst.url ? ccst.url : 'https://s3.eu-central-1.amazonaws.com/jongfresh-dev/products/images/jongfresh_kist.png'" />
                        </div>
                        <div class="col-5">
                            <div class="list-text-label">Naam</div>
                            <div class="list-text">{{ ccst.name }}</div>
                        </div>
                        <div class="col-2">
                            <div class="list-text-label">Bosopdrachten</div>
                            <div class="list-text">{{ ccst.harvestAssignmentsHarvested }} / {{ ccst.harvestAssignmentsNeeded }}</div>
                        </div>
                        <div class="col-2">
                            <div class="list-text-label">Eigen registraties</div>
                            <div class="list-text">{{ ccst.harvestRegistrations }}</div>
                        </div>
                        <div class="col-2 day-harvest-status">
                            <div class="list-text-label">Dagoogst</div>
                            <div class="list-text">{{ ccst.dayHarvestRegistrations }} / {{ ccst.dayHarvestRegistrationsNeeded }}</div>
                        </div>
                    </div>

                    <template
                        v-if="opened && (opened.name === ccst.name) || openAll"
                    >
                        <div v-for="dataItem of ccst.data" class="list-item" :class="{
                        }">
                            <div class="container">

                                <HarvestAssignmentRow
                                    :harvestAssignment="dataItem"
                                    v-if="dataItem.getModelName() === 'harvest-assignment'"
                                    :harvestAssignmentOngoing="harvestAssignmentOngoing"
                                    :date="date"
                                />

                                <HarvestRegistrationRow
                                    @click="opened = (opened === dataItem ? null : dataItem)"
                                    :harvestRegistration="dataItem"
                                    v-if="dataItem.getModelName() === 'harvest-registration'"
                                />

                                <DayHarvestRegistrationRow
                                    @click="opened = (opened === dataItem ? null : dataItem)"
                                    :dayHarvestRegistration="dataItem"
                                    v-if="dataItem.getModelName() === 'day-harvest-registration'"
                                />
                            </div>
                        </div>
                        <div>
                            <button
                                class="styled-button"
                                @click="createDayHarvestRegistration = {
                                    chestId: ccst.chest_id,
                                    contentId: ccst.content_id,
                                    sizeId: ccst.size_id,
                                    typeId: ccst.type_id,
                                    date: date
                                }"
                            >
                                <Icon name="plus" style="width: 18px; height: 18px;" />
                                Nieuwe dagoogst registratie
                            </button>
                        </div>
                    </template>
                </div>
            </div>

            <div class="list-item" v-for="dayHarvestKilo of dayHarvestKilos.filter(dayHarvestKilo =>
                typeIds.length <= 0 || typeIds.includes(dayHarvestKilo.typeId)
                || typeIds.includes('other') && ! filterTypes.pluck('id').contains(dayHarvestKilo.typeId)
            )">
                <div class="container">
                    <div class="row">
                        <div class="col-1">
                            <img style="width: 100%; border-radius: 3px;" :src="dayHarvestKilo.imageUrl ? dayHarvestKilo.imageUrl : 'https://s3.eu-central-1.amazonaws.com/jongfresh-dev/products/images/jongfresh_kist.png'" />
                        </div>
                        <div class="col-5">
                            <div class="list-text-label">Naam</div>
                            <div class="list-text">{{ dayHarvestKilo.name() }}</div>
                        </div>
                        <div class="col-2">
                            <div class="list-text-label">Tijd</div>
                            <div class="list-text">{{ dayHarvestKilo.createdAt.format('HH:mm') }}</div>
                        </div>
                        <div class="col-2">
                            <div class="list-text-label">Medewerker</div>
                            <div class="list-text">{{ dayHarvestKilo.user.name() }}</div>
                        </div>
                        <div class="col-2">
                            <div class="list-text-label">Kg</div>
                            <div class="list-text">{{ dayHarvestKilo.kilo() }}</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>

    <CreateDayHarvestRegistrationModal
        v-bind="createDayHarvestRegistration"
        v-if="createDayHarvestRegistration"
        @close="createDayHarvestRegistration = null"
        @stored="getData()"
    />

    <CreateEditDayHarvestKiloModal
        v-bind="createEditDayHarvestKilo"
        v-if="createEditDayHarvestKilo"
        @close="createEditDayHarvestKilo = null"
        @stored="getData();"
        :types="types"
    />

</template>

<script setup>
    import { ref, computed } from 'vue';
    import { useRoute } from 'vue-router';
    import collect from 'collect.js'
    import moment from 'moment';
    import HarvestAssignmentService from '@/services/http/harvest-assignment-service';
    import DayHarvestRegistrationService from '@/services/http/day-harvest-registration-service';
    import HarvestRegistrationService from '@/services/http/harvest-registration-service';
    import DayHarvestKiloService from '@/services/http/day-harvest-kilo-service';
    import HarvestAssignmentRow from './HarvestAssignmentRow.vue';
    import DayHarvestKiloRow from './DayHarvestKiloRow.vue';
    import DayHarvestRegistrationRow from './DayHarvestRegistrationRow.vue';
    import HarvestRegistrationRow from './HarvestRegistrationRow.vue';
    import CreateDayHarvestRegistrationModal from './CreateDayHarvestRegistrationModal.vue';
    import CreateEditDayHarvestKiloModal from './CreateEditDayHarvestKiloModal.vue';
    import TypeService from '@/services/http/type-service';
    import MultiSelect from '@/components/Form/MultiSelect.vue';
    import { useCcstStore } from '@/stores/ccst';
    import harvestProductService from '@/services/http/harvest-product-service';


    const emit = defineEmits(['breadcrumbs']);

    const route = useRoute();
    const date = ref(route.query.date_string ? route.query.date_string : moment().format('YYYY-MM-DD'));
    const ccst = useCcstStore();

    useCcstStore()

    /* const rushHarvestAssignmentsGroups = ref(collect());
    const harvestAssignmentsGroups = ref(collect());
    const harvestRegistrationsGroups = ref(collect()); */

    const harvestRegistrations = ref(collect());
    const harvestAssignments = ref(collect());
    const dayHarvestRegistrations = ref(collect());

    const ccsts = ref(collect());
    const dayHarvestKilos = ref(collect());

    const harvestAssignmentOngoing = ref([]);

    const opened = ref(null);
    const openAll = ref(false);

    const types = ref(collect());
    const filterTypes = ref(collect());
    const typeIds = ref([]);

    const createDayHarvestRegistration = ref(null);

    const createEditDayHarvestKilo = ref(null);

    const getHarvestAssignments = function() {
        return HarvestAssignmentService.get({
            include: [
                'harvestProductImageUrl', 'users.activeWorkSession', 'users.avatars', 'harvestRegistrations',
                'chest', 'content', 'size', 'type', 'location'
            ],
            filters: {
                date_for_day_harvest: date.value,
                order_by: 'sequence',
            }
        });
    }

    const getHarvestRegistrations = function() {
        return HarvestRegistrationService.get({
            include: [
                'harvestProductImageUrl',
                'chest', 'content', 'size', 'type', 'user', 'location'
            ],
            filters: {
                0: 'harvest_assignment_id_is_null',
                date_is: date.value
            }
        });
    }

    const getDayHarvestRegistrations = function() {
        return DayHarvestRegistrationService.get({
            include: [
                'harvestProductImageUrl', 'chest', 'content', 'size', 'type', 'user'
            ],
            filters: {
                date_is: date.value
            }
        });
    }

    const getData = function() {
        getDayHarvestKilos();
        Promise.all([
            getHarvestRegistrations(),
            getHarvestAssignments(),
            getDayHarvestRegistrations()
        ]).then(([harvestRegistrationModels, harvestAssignmentModels, dayHarvestRegistrationModels]) => {
            harvestRegistrations.value = harvestRegistrationModels;
            harvestAssignments.value = harvestAssignmentModels;
            dayHarvestRegistrations.value = dayHarvestRegistrationModels;

            createDayHarvestRegistration.value = null;
            createEditDayHarvestKilo.value = null;

            ccsts.value = collect([
                ...harvestAssignments.value.all(),
                ...harvestRegistrations.value.all(),
                ...dayHarvestRegistrations.value.all()
            ])
            .groupBy(ccst => [ccst.chestId, ccst.contentId, ccst.sizeId, ccst.typeId].join(','))
            .map(items => ({
                data: items,
                ...items.first().getCcst(),
                mini: items.first().size.name === 'mini',
                name: items.first().name(),
                url: items.first().imageUrl,
                harvestAssignmentsHarvested: items.sum(
                    i => (i.getModelName() === 'harvest-assignment' ? i.harvested : 0)
                ),
                harvestAssignmentsNeeded: items.sum(
                    i => (i.getModelName() === 'harvest-assignment' ? i.amount : 0)
                ),
                harvestRegistrations: items.sum(i => {
                    if (['harvest-registration'].includes(i.getModelName())) {
                        return i.harvested
                    }

                    return 0;
                }), // Bosopdracht total
                dayHarvestRegistrations: items.sum(
                    i => (i.getModelName() === 'day-harvest-registration' ? i.amount : 0)
                ),
                dayHarvestRegistrationsNeeded: items.sum(
                    i => {
                        if (i.getModelName() === 'harvest-assignment') {
                            return i.amountForStock();
                        } else if (i.getModelName() === 'harvest-registration') {
                            return i.harvested;
                        }

                        return 0;
                    }
                ), // Registraties
            })).values().sortBy('mini')
        });
    }

    const getDayHarvestKilos = function() {
        return DayHarvestKiloService.get({
            include: ['harvestProductImageUrl', 'size', 'type', 'user', 'location'],
            filters: {
                date_is_greater_or_equal_than: `${date.value} 00:00:00`,
                date_is_lower_or_equal_than: `${date.value} 23:59:59`
            }
        }).then(dayHarvestKiloModels => {
            dayHarvestKilos.value = dayHarvestKiloModels;
        });
    }

    const getTypes = function() {
        return TypeService.get({filters: {order_by: 'sequence'}}).then(typeModels => {
            types.value = typeModels;
            filterTypes.value = typeModels.filter(t => ['red', 'white', 'whitepoint', 'icicles', 'mix', 'rainbow'].includes(t.name));
        })
    }

    emit('breadcrumbs', [
        {label: 'Oogstregistraties', to: '/registrations'},
        {label: 'Dagoogst'},
    ]);

    getData();
    getTypes();
</script>
