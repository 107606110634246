<template>
    <div class="container">

        <!-- HEADER & FILTERS -->
        <div class="row">
            <div class="col">
                <div class="page-header">
                    <h1>{{ article ? article.name : '-' }}</h1>
                </div>
            </div>
        </div>

        <div class="page-header-settings">
            <div class="row">
                <div class="col-6 col-md-4 col-lg-3 datepicker-styling">
                    <span class="styled-label">Van</span>
                    <DatePicker :clearable="false" v-model="from" />
                </div>
                <div class="col-6 col-md-4 col-lg-3 datepicker-styling">
                    <span class="styled-label">Tot</span>
                    <DatePicker :clearable="false" v-model="till" />
                </div>
            </div>
        </div>

        <!-- PRICE HISTORY -->
        <table v-if="filteredPrices.count() > 0" class="styled-table">

            <thead class="styled-table-header">
                <tr>
                    <th>
                        Datum
                    </th>
                    <th>
                        Nieuwe prijs
                    </th>
                    <th style="text-align: left;">
                        Door
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr class="styled-table-row" v-for="filteredPrice of filteredPrices">
                    <td>{{ filteredPrice.createdAt.format('DD-MM-YYYY HH:mm') }}</td>
                    <td>{{ formatNumberAsMoney(filteredPrice.cents/100) }}</td>
                    <td style="text-align: left;">
                        {{ filteredPrice.user ? filteredPrice.user.name() : '-' }}
                    </td>
                </tr>
            </tbody>
        </table>
        <div v-if="filteredPrices.count() === 0" class="no-data-placeholder">
            Er zijn geen prijswijzigingen beschikbaar.
        </div>

    </div>

</template>

<script setup>
    import { ref, computed } from 'vue';
    import articleService from '@/services/http/article-service';
    import moment from 'moment';
    import Article from '@/models/article';
    import UsePriceFunctions from './UsePriceFunctions';

    const props = defineProps(['id']);
    const emit = defineEmits(['breadcrumbs']);

    const { formatNumberAsMoney } = UsePriceFunctions()

    const article = ref(new Article());

    const from = ref(moment().subtract(1, 'year').startOf('year'));
    const till = ref(moment());

    /*
    |--------------------------------------------------------------------------
    | Parse Prices
    |--------------------------------------------------------------------------
    */
    const filteredPrices = computed(() =>
        article.value.prices.filter(price =>
            price.createdAt.isBetween(
                from.value.clone().startOf('day'),
                till.value.clone().endOf('day')
            )
        ).sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)
    );


    /*
    |--------------------------------------------------------------------------
    | Get article
    |--------------------------------------------------------------------------
    */
    const getArticle = function() {
        return articleService.getById(props.id, {include: ['prices.user']}).then(articleModel => {
            article.value = articleModel;

            emit('breadcrumbs', [
                {label: 'Prijzen', to: '/prices'},
                {label: article.value.name}
            ]);
        })
    }
    getArticle();

</script>
