<template>
    <div>

        <div class="container">
            <div class="row">
                <div class="col">
                    <div class="page-header">
                        <h1>Beschikbare oppervlakte</h1>
                    </div>
                    <div class="page-header-settings">
                        <div class="row">
                            <div class="col-6 col-md-4 col-lg-3">
                                <div class="form-control datepicker-styling">
                                    <span class="styled-label">
                                        Van
                                    </span>
                                    <DatePicker
                                        v-model="f"
                                        @input="dateChanged()"
                                    />
                                    <!-- <datepicker
                                        :format="customFormatter"
                                        @closed="dateChanged()"
                                        :language="nl"
                                        v-model="f"
                                        :monday-first="true"
                                        class="form-control datepicker-styling"
                                    ></datepicker> -->
                                </div>
                            </div>
                            <div class="col-6 col-md-4 col-lg-3">
                                <div class="form-control datepicker-styling">
                                    <span class="styled-label">
                                        Tot
                                    </span>
                                    <DatePicker
                                        v-model="t"
                                        @input="dateChanged()"
                                    />
                                    <!-- <datepicker
                                        :format="customFormatter"
                                        @closed="dateChanged()"
                                        :language="nl"
                                        v-model="t"
                                        :monday-first="true"
                                        class="form-control datepicker-styling"
                                    ></datepicker> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style="overflow-x: scroll;">
            <table class="styled-table" :style="`width: ${560 + (weeks.length * 120)}px;`">
                <thead class="styled-table-header">
                    <tr>
                        <th>
                            <div style="width:200px; text-align:left">
                                Radijs
                            </div>
                        </th>
                        <th v-for="(week, index) of showWeeks" :key="index" :class="{current: week.isSame(today, 'isoWeek')}">
                            <div class="week-number">
                                {{ week.isoWeek() }}
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody class="styled-table-body" v-if="data && types && articles && tubersPerType && availableSurfaces">
                    <tr>
                        <td>
                            <div style="width: 200px;">
                                Benodigd m2
                            </div>
                        </td>
                        <td v-for="(week, index) of showWeeks" :class="{current: week.isSame(today, 'isoWeek')}">
                            <div class="week-content">
                                {{ Math.round(data[index].total) }}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div style="width: 200px;">
                                Beschikbare m2
                            </div>
                        </td>
                        <td v-for="(week, index) of showWeeks" :key="index" :class="{current: week.isSame(today, 'isoWeek')}">
                            <div class="week-content">
                                <input v-model="availableSurfaces[index]" @input="updateAvailableSurface(index)" style="width: 100px; text-align:center; border-radius: 5px; border:1px solid #999" type="number">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style="border-top: 2px solid #333">
                            <div style="width: 200px;">
                                Resultaat
                            </div>
                        </td>
                        <td style="border-top: 2px solid #333" v-for="(week, index) of showWeeks" :class="{current: week.isSame(today, 'isoWeek')}">
                            <div class="week-content">
                                {{ availableSurfaces[index] - Math.round(data[index].total)}}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>

</template>

<script>
    import moment from 'moment';
    import typeService from '../../services/http/type-service';
    import saleIgnoreService from '../../services/http/sale-ignore-service';
    import { collect } from "collect.js";
    import saleForecastCalculations from './mixins/sale-forecast-calculations';
    import tubersPerArticleService from '../../services/http/tubers-per-article-service';
    import seedsPerSquaredMeterService from '../../services/http/seeds-per-squared-meter-service';
    import cultivationCalendarDayService from '../../services/http/cultivation-calendar-day-service';
    import availableSurfaceService from '../../services/http/available-surface-service';


    export default {
        props: [],
        mixins: [saleForecastCalculations],

        data() {
            return {
                articles: false,
                saleIgnoresPerType: false,
                tubersPerType: false,
                types: false,

                saleForecasts: collect(),

                data: false,

                gains: {},

                articles: collect(),
                cultivationCalendarDays: false,
                seedsPerSquaredMeters: false,

                availableSurfaces: false,
                availableSurfacesTimeout: {},
            }
        },

        computed: {
            showWeeks() {
                let weeks = [];
                let till = moment(this.t);
                let from = moment(this.f);

                if (this.from) {
                    for (let weekNr = 0; weekNr <= till.diff(from, 'week'); weekNr++) {
                        weeks.push(from.clone().add(weekNr, 'week'));
                    }
                }

                return weeks;
            },

            seedPerSquaredMetersGrouped() {
                return this.seedsPerSquaredMeters.groupBy('typeId');
            }
        },

        methods: {

            // ---------------------------------------------------------------------------------------------------------------------
            // Dates
            // ---------------------------------------------------------------------------------------------------------------------
            dateChanged() {
                this.from = moment(this.f).startOf('isoWeek');
                this.till = moment(this.t).endOf('isoWeek');


                this.from.add(this.cultivationCalendarDays.first(ccd => (
                    ccd.month === this.from.month()+1,
                    ccd.dayOfMonth === this.from.day()
                )).days, 'day').startOf('isoWeek');

                this.till.add(this.cultivationCalendarDays.first(ccd => (
                    ccd.month === this.till.month()+1,
                    ccd.dayOfMonth === this.till.date()
                )).days, 'day').endOf('isoWeek');


                this.setDates(this.from, this.till);
                let saleIgnoresPerType = this.getSaleIgnoresPerType();
                let tubersPerType = this.getTubersPerType();
                let availableSurfaces = this.getAvailableSurfaces();

                this.data = false;

                Promise.all([saleIgnoresPerType, tubersPerType, availableSurfaces]).then(() => {
                    this.setData();
                });
            },


            // ---------------------------------------------------------------------------------------------------------------------
            // Data
            // ---------------------------------------------------------------------------------------------------------------------
            setData()
            {
                let data = [];

                let gains = {};


                for (const type of this.types) {
                    gains[type.id] = [];
                }

                for (let week of this.weeks) {

                    const weekTubersPerType = this.getWeeklyTubers(this.tubersPerType, week.current);
                    const prevWeekTubersPerType = this.getWeeklyTubers(this.tubersPerType, week.prev);
                    const saleIgnoresPerType = this.getWeeklyTubers(this.saleIgnoresPerType, week.current);
                    const prevSaleIgnoresPerType = this.getWeeklyTubers(this.saleIgnoresPerType, week.prev);
                    const weekData = {total: 0};

                    for (const type of this.types) {

                        if (week.current.isSameOrBefore(this.today, 'isoWeek')) {
                            weekData[type.id] = this.getTubers(weekTubersPerType, type) - this.getTubers(saleIgnoresPerType, type);
                        } else {
                            weekData[type.id] = Math.round((
                                this.getTubers(prevWeekTubersPerType, type)
                                -
                                this.getTubers(prevSaleIgnoresPerType, type)
                            ));
                        }
                        weekData.total += weekData[type.id];
                    }

                    weekData['cultivationCalendarDays'] = this.cultivationCalendarDays.filter(ccd => (ccd.harvestDate.isoWeek() === week.current.isoWeek())).count();

                    weekData['week'] = `${week.current.isoWeekYear()}-${week.current.isoWeek()}`;

                    data.push(weekData);
                }

                this.data = [];
                for (let week of this.showWeeks) {
                    const weekData = {total: 0};
                    for (const type of this.types) {
                        weekData[type.id] = 0;
                    }
                    const current = week.clone().startOf('isoWeek');

                    for (let day = 1; day <= 7; day++) {
                        const cultivationCalendarDay = this.cultivationCalendarDays.first(ccd => (
                            ccd.sowingDate.month() === current.month()
                            && ccd.sowingDate.date() === current.date()
                        ));
                        const currentHarvestDate = current.clone().add(cultivationCalendarDay.days, 'day');
                        const forecastData = data.find(d => {
                            return d.week === `${currentHarvestDate.isoWeekYear()}-${currentHarvestDate.isoWeek()}`
                        });

                        for (const type of this.types) {
                            if (forecastData) {
                                weekData[type.id] += forecastData[type.id]/forecastData['cultivationCalendarDays'];
                            } else {
                                weekData[type.id] += 0;
                            }
                        }

                        current.add(1, 'day')

                    }

                    for (const type of this.types) {
                        if (weekData[type.id] !== 0) {
                            weekData[type.id] = ((weekData[type.id])/this.seedPerSquaredMetersGrouped.get(type.id).firstWhere('weekNumber', current.isoWeek()).seeds);
                        }

                        weekData.total += weekData[type.id];
                    }

                    this.data.push(weekData);
                }
            },

            getWeeklyTubers(objects, week) {
                return objects.filter(o => o.week == week.format('YYYYWW'));
            },

            getTubers(objects, type) {
                let object = objects
                 .find(o => o.type_id === type.id);

                return object ? object.amount : 0;
            },

            // ---------------------------------------------------------------------------------------------------------------------
            // AvailableSurface
            // ---------------------------------------------------------------------------------------------------------------------


            getAvailableSurfaces()
            {
                this.availableSurfaces = false;
                return availableSurfaceService.get().then(availableSurfaces => {

                    this.setAvailableSurfaces(availableSurfaces)
                });
            },

            setAvailableSurfaces(availableSurfaces) {
                this.availableSurfaces = [];
                for (let week of this.weeks) {
                    let availableSurface = availableSurfaces.first(as => as.at.isSame(week.current, 'isoWeek'));

                    this.availableSurfaces.push(availableSurface !== undefined ? availableSurface.m2 : 0);
                }
            },

            updateAvailableSurface(index) {
                if (index in this.availableSurfacesTimeout) {
                    clearTimeout(this.availableSurfacesTimeout[index]);
                }

                this.availableSurfacesTimeout[index] = setTimeout(() => {
                    availableSurfaceService.create({
                        at: this.weeks[index].current.format('YYYY-MM-DD'),
                        m2: this.availableSurfaces[index]
                    });
                }, 500);
            },

            // ---------------------------------------------------------------------------------------------------------------------
            // Other
            // ---------------------------------------------------------------------------------------------------------------------

            getTypes() {
                return typeService.get({include: ['children.breedPerTypes.breed', 'children.breedPerTypes.fraction']}).then(types => {
                    this.types = types;
                });
            },

            getSaleIgnoresPerType()  {
                this.saleIgnoresPerType = false;
                return saleIgnoreService.getSaleIgnoresPerType(this.betweens).then((saleIgnoresPerType) => {
                    this.saleIgnoresPerType = saleIgnoresPerType;
                });
            },

            getTubersPerType() {
                this.tubersPerType = false;
                return tubersPerArticleService.getTubersPerType(this.betweens).then((tubersPerType) => {
                    this.tubersPerType = tubersPerType;
                });
            },

            getCultivationCalendarDays() {
                return cultivationCalendarDayService.get().then(cultivationCalendarDays => {
                    this.cultivationCalendarDays = cultivationCalendarDays;
                });
            },

            getSeedsPerSquaredMeters()
            {
                return seedsPerSquaredMeterService.get().then(seedsPerSquaredMeters => {
                    this.seedsPerSquaredMeters = seedsPerSquaredMeters;
                });
            },
        },

        created() {
            Promise.all([
                this.getTypes(),
                this.getCultivationCalendarDays(),
                this.getSeedsPerSquaredMeters(),
            ]).then(() => {
                this.dateChanged();
            });

            this.$emit('breadcrumbs', [
                {to: `/sowing-planning`, label: 'Zaaiplanning'},
                {label: 'Beschikbare oppervlakte'}
            ]);
        }
    }
</script>
