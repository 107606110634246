<template>
    <div class="container">

        <div class="notification error" v-if="failedUpdatedArticleIds.length > 0">
            Voor de onderstaande artikelen is het niet gelukt om de prijs te wijzigen in Exact:
            <div v-for="articleId in failedUpdatedArticleIds">
                {{ affectedArticles.find(a => a.id === articleId).name }}
            </div>
        </div>

        <!-- HEADER & FILTERS -->
        <div class="row">
            <div class="col">
                <div class="page-header">
                    <h1>Controle</h1>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <input class="styled-input" type="text" placeholder="Zoeken" v-model="search">
            </div>
        </div>

        <div class="prices-subject">
            <h3>
                {{ article.name }}
            </h3>
            <div class="row">
                <div class="col-4">
                    <div class="prices-subject-info">
                        <div class="prices-subject-label">
                            Huidige prijs
                        </div>
                        <div class="prices-subject-value">
                            {{ formatNumberAsMoney(article.cents/100) }}
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="prices-subject-info">
                        <div class="prices-subject-label">
                            Nieuwe prijs
                        </div>
                        <div class="prices-subject-value">
                            {{ formatNumberAsMoney(isGram ? cents*10 : cents/100) }}
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="prices-subject-info">
                        <div class="prices-subject-label">
                            Verschil in prijs
                        </div>
                        <div class="prices-subject-value">
                            {{ formatNumberAsMoney(isGram ? diff*0.01 : diff/100)  }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- AFFECTED ARTICLES -->
        <table class="styled-table">

            <thead class="styled-table-header">
                <tr>
                    <th>
                        Art nr.
                    </th>
                    <th style="text-align: left;">
                        Artikel
                    </th>
                    <th>
                        Huidge prijs
                    </th>
                    <th>
                        Nieuwe prijs
                    </th>
                    <th>
                        Verschil
                    </th>
                    <th>
                        &nbsp;
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    class="article-price-row styled-table-row"
                    v-for="affectedArticle in filteredArticles"
                    :class="{
                        'dont-commit': dontCommitIds.includes(affectedArticle.id),
                        'success': successUpdatedArticleIds.includes(affectedArticle.id),
                        'failed': failedUpdatedArticleIds.includes(affectedArticle.id)
                    }"
                >
                    <td>
                        {{ affectedArticle.code }}
                    </td>

                    <td style="text-align: left;">
                        {{ affectedArticle.name }}
                    </td>

                    <td
                        :class="{manual: affectedArticle.oldManual}"
                        v-tooltip="affectedArticle.oldManual ? 'Handmatig ingevulde datum' : ''"
                    >
                        {{ formatNumberAsMoney(affectedArticle.oldCents/100) }}
                        <a style="cursor:pointer" @click="childArticlesModalArticle = affectedArticle.id" v-tooltip="'Bekijk stuklijst'">
                            <Icon style="width: 18px" name="question-mark-circle" />
                        </a>
                    </td>

                    <td>
                        <label class="new-price-input">
                            <input
                                v-if="! stored"
                                :disabled="dontCommitIds.includes(affectedArticle.id)"
                                type="number" min="1" step="1"
                                onkeydown="if(['.', ',','-','+','e','E'].includes(event.key)){event.preventDefault();}"
                                v-model="affectedArticle.newCents"
                                class="styled-input"
                                @input="priceChanged(affectedArticle.id, affectedArticle.newCents)"
                            >
                            <div class="new-price-presenter" style="top: 22px" v-if="! stored">
                                {{ formatNumberAsMoney(affectedArticle.newCents/100) }}
                            </div>
                            <div v-else>
                                {{ formatNumberAsMoney(affectedArticle.newCents/100) }}
                            </div>
                        </label>
                    </td>

                    <td>
                        {{ formatNumberAsMoney((affectedArticle.newCents - affectedArticle.oldCents)/100) }}
                    </td>

                    <td>
                        <label v-if="affectedArticle.id !== article.id && ! stored">
                            <input :disabled="stored" type="checkbox" :value="affectedArticle.id" v-model="dontCommitIds">
                            Negeren
                        </label>
                    </td>
                </tr>
            </tbody>

        </table>

        <div class="row">
            <div class="col">
                <button v-if="! stored" class="button success" @click="confirmStore = true">
                    Opslaan
                </button>
                <button v-else class="button success" @click="router.push(`/prices`);">
                    Sluiten
                </button>
            </div>
        </div>
    </div>

    <ChildArticlesModal
        v-if="childArticlesModalArticle"
        :excludeSaveButton="true"
        :articleId="childArticlesModalArticle"
        @close="childArticlesModalArticle = null"
    />

    <ConfirmModal
        v-if="confirmStore"
        title="Opslaan"
        body="De nieuwe prijzen worden direct doorgevoerd in Exact Online. Wil je doorgaan?"
        @yes="store()"
        @no="confirmStore = false"
    />

    <LoaderWithOverlay v-if="loading" />
</template>

<script setup>
    import articleService from '@/services/http/article-service';
    import { ref, computed } from 'vue';
    import collect from 'collect.js';
    import UsePriceFunctions from './UsePriceFunctions';
    import Article from '@/models/article';
    import ConfirmModal from '@/components/ConfirmModal.vue'
    import priceService from '@/services/http/price-service';
    import { useRouter } from 'vue-router';
    import { useToast } from 'vue-toast-notification';
    import LoaderWithOverlay from '@/components/LoaderWithOverlay.vue';
    import ChildArticlesModal from './ChildArticlesModal.vue';

    const props = defineProps(['id', 'cents'])
    const emit = defineEmits(['breadcrumbs'])

    const { formatNumberAsMoney } = UsePriceFunctions();
    const router = useRouter();

    const failedUpdatedArticleIds = ref([]);
    const successUpdatedArticleIds = ref([]);

    const article = ref(new Article({}));
    const affectedArticles = ref(collect());
    const diff = ref(0);

    const dontCommitIds = ref([]);

    const search = ref('');

    const confirmStore = ref(false);

    const loading = ref(false);

    const isGram = ref(false);

    const stored = ref(false);

    const childArticlesModalArticle = ref(null);
    /*
    |--------------------------------------------------------------------------
    | Store price
    |--------------------------------------------------------------------------
    */
    const store = function() {
        const storeArticles = filteredArticles.value.filter(a => ! dontCommitIds.value.includes(a.id));
        loading.value = true;
        confirmStore.value = false;

        priceService.storeMany(
            {new_article_prices: storeArticles.map(a => ({id: a.id, cents: a.newCents, manual_price: a.manual}))}
        ).then((response) => {
            // Set failed updated article ids by checking the returned succesfull update ids.
            failedUpdatedArticleIds.value = storeArticles.map(a => a.id).filter(article_id => (
                ! response.data.includes(article_id)
            ));
            successUpdatedArticleIds.value = storeArticles.map(a => a.id).filter(article_id => (
                response.data.includes(article_id)
            ));

            loading.value = false;
            if (failedUpdatedArticleIds.value.length > 0) {
                useToast().error('Niet alle prijzen doorgevoerd');
            } else {
                useToast().success('Prijzen opgeslagen');
            }
            stored.value = true;
            //router.push(`/prices`);
        });
    }


    /*
    |--------------------------------------------------------------------------
    | Get article & articles tree
    |--------------------------------------------------------------------------
    */

    const filteredArticles = computed(() => {
        if (! search.value) return affectedArticles.value;

        return affectedArticles.value.filter(a => {
            const name = a.name.toLowerCase();
            const searchLowered = search.value.toLowerCase();

            return name.includes(searchLowered) || a.externalCode.includes(searchLowered);
        });
    });

    const getArticle = function() {
        return articleService.getById(props.id, {include: ['parentArticlesTree', 'unit']}).then(articleModel => {
            article.value = articleModel;
            isGram.value = (articleModel.unit.name === 'Gram');
            diff.value = ((isGram.value ? props.cents*1000 : props.cents) - article.value.cents);
            affectedArticles.value = getAllArticlesInTree(article.value);
        });
    }
    getArticle();

    const getAllArticlesInTree = function(treeArticle, articles = [], amount = 1)
    {
        const newCents = Math.round(
            (isGram.value && article.value.id !== treeArticle.id)
            ? (treeArticle.cents + (amount * diff.value * 0.001))
            : (treeArticle.cents + (amount * diff.value))
        );

        articles.push({
            id: treeArticle.id,
            name: treeArticle.name,
            code: treeArticle.externalCode,
            oldManual: treeArticle.manualPrice,
            oldCents: treeArticle.cents,
            newCents: newCents,
            originalNewCents: newCents,
            manual: false,
            externalCode: treeArticle.externalCode
        })

        for (const parentTreeArticle of treeArticle.parentArticlesTree.filter(a => ! a.deletedAt)) {
            articles = getAllArticlesInTree(parentTreeArticle, articles, amount * parentTreeArticle.pivot.amount)
        }

        return articles;
    }

    /*
    |--------------------------------------------------------------------------
    | Other
    |--------------------------------------------------------------------------
    */

    const priceChanged = function(articleId, cents) {
        const article = affectedArticles.value.find(a => articleId === a.id);
        article.manual = cents !== article.originalNewCents;
    }

    emit('breadcrumbs', [
        {label: 'Prijzen', to: '/prices'},
        {label: 'Controle'}
    ]);
</script>
