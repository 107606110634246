import {AbstractModel} from './abstract-model.js'

export default class Permission extends AbstractModel {

    getDefaults() {
        return {
            id: null,
            name: null,
            web: null,
            app: null,
        }
    }

    translation() {
        return 'Permission';
    }

    present(field) {
        const translations =  {
            "name": {
                "harvest_assignment": "Bestellingen",
                "mini_harvest_assignment": "Mini bestellingen",
                "own_harvest_registration": "Eigen registratie",
                "orders": "Orders Exact",
                "harvest_assignments_planning": "Bosopdrachten",
                "harvest_schedule": "Werkverdeling",
                "registrations": "Oogstregistratie",
                "harvest_report": "Oogst totalen",
                "stock": "Voorraad",
                "own_work_list": "Eigen werklijst",
                "work_lists": "Werklijsten",
                "purchase": "Inkopen",
                "dashboard_presence": "Dashboard - Aanwezigheid",
                "dashboard_planning": "Dashboard - planning",
                "dashboard_user_notifications": "Dashboard - Berichten",
                "dashboard_unassigned_harvest_assignments": "Dashboard - Niet ingedeeld",
                "dashboard_order_notifications": "Dashboard - Ordermeldingen",
                "transport": "Transport",
                "day_harvest": "Dagoogst",
                "articles": "Artikelen",
                "tasks": "Taken",
                "users": "Medewerkers",
                "roles": "Rollen",
                "logs": "Logboek",
                "assortments": "Assortimenten",
                "formula": "Formule doorlooptijden",
                "performance": "Prestaties",
                "settings": "Instellingen",
                "harvest_assignments_report": "Bosopdracht rapportage",

                "admin_roles_crud": "",
                "horizon": "",

                "exact_restore": "exact herstel",

                "reviews": "Beoordelingen",
                "own_reviews": "Eigen beoordelingen",
                "notifications": "Notificaties",

                'sowing_planning': 'Zaaiplanning',
                'sowing_planning_settings': 'Zaaiplanning instellingen',

                'production_control_admin': 'Productie control admin',
                'production_control_employee': 'Productie control medewerker',

                'production_forecast': 'Productieprognose',

                'customer-insights': 'Insights',

                'prices': 'Prijzen',
            },

            "description": {
                "harvest_assignment": "Deze permissie geeft toegang tot 'Bestellingen' op de app.",
                "performance": "Deze permissie geeft toegang tot 'Prestaties' op het web.",
                "mini_harvest_assignment": "Deze permissie geeft toegang tot 'Mini bestellingen' op de app.",
                "own_harvest_registration": "Deze permissie geeft toegang tot 'Eigen registratie' op de app.",
                "orders": "Deze permissie geeft toegang tot 'Orders Exact' op het web en de app.",
                "harvest_assignments_planning": "Deze permissie geeft toegang tot 'Bosopdrachten' op het web en de app.",
                "harvest_schedule": "Deze permissie geeft toegang tot 'Werkverdeling' op het web.",
                "registrations": "Deze permissie geeft toegang tot 'Oogstregistratie' op het web.",
                "harvest_report": "Deze permissie geeft toegang tot 'Oogst totalen' op het web.",
                "stock": "Deze permissie geeft toegang tot 'Voorraad' op het web.",
                "own_work_list": "Deze permissie geeft toegang tot 'Mijn werklijst' op het web en de app.",
                "work_lists": "Deze permissie geeft toegang tot 'Werklijsten' op het web.",
                "purchase": "Deze permissie geeft toegang tot 'Inkopen' op het web.",
                "dashboard_presence": "Deze permissie activeert de aanwezigheid widget op het dashboard.",
                "dashboard_planning": "Deze permissie activeert de planning widget op het dashboard.",
                "dashboard_user_notifications": "Deze permissie activeert de berichten op het dashboard.",
                "dashboard_unassigned_harvest_assignments": "Deze permissie activeert de 'Bosopdrachten zonder medewerkers' widget op het dashboard.",
                "dashboard_order_notifications": "Deze permissie activeert de ordermeldingen op het dashboard.",
                "transport": "Deze permissie geeft toegang tot 'Transport' op de app.",
                "day_harvest": "Deze permissie geeft toegang tot 'Dagoogst' op de app.",
                "articles": "Deze permissie geeft toegang tot 'Artikelen' op het web.",
                "tasks": "Deze permissie geeft toegang tot 'Taken' op het web.",
                "users": "Deze permissie geeft toegang tot 'Medewerkers' op het web.",
                "roles": "Deze permissie geeft toegang tot 'Rollen' op het web.",
                "logs": "Deze permissie geeft toegang tot 'Logboek' op het web.",
                "assortments": "Deze permissie geeft toegang tot 'Assortimenten' op het web.",
                "formula": "Deze permissie geeft toegang tot 'Formule doorlooptijden' op het web.",
                "harvest_assignments_report": "Deze permissie geeft toegang tot 'Bosopdracht rapportage' op het web.",
                "settings": "Deze permissie geeft toegang tot 'Instellingen' op het web.",

                "exact_restore": "Deze permissie geeft toegang tot 'Herstel exact' en 'Synchroniseer exact' op het web",

                "admin_roles_crud": "",
                "horizon": "",

                "reviews": "Deze permissie geeft toegang tot 'Beoordelingscategorieën' op het web en 'Beoordelingen' op de app.",
                "own_reviews": "Deze permissie geeft toegang tot 'Mijn beoordelingen' op de app.",
                "notifications": "Deze permissie geeft toegang tot 'Meldingen' op het web.",

                'production_control_admin': 'Deze permissie geeft toegang tot \'Production control\' op het web',
                'production_control_employee': 'Deze permissie geeft toegang tot \'Production control\' op het app',


                'production_forecast': 'Deze permissie geeft toegang tot \'Productieprognose\' op het web',

                'customer-insights': 'Deze permissie geeft toegang tot \'Insights\' op het web',

                'prices': 'Deze permissie geeft toegang tot \'Prijzen\' op het web',
            }
        }

        return translations[field][this.name];
    }

}
