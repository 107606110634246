<template>
    <modal>
        <template v-slot:header>
            {{ article.name }}
        </template>

        <div class="container">
            <div class="row" style="font-weight: bold;">
                <div class="col-8">Naam</div>
                <div class="col-2 text-center">Aantal</div>
                <div class="col-2">Prijs</div>
            </div>
            <div class="row" v-for="articleInfo in flattenedTree" style="border-top: 1px solid #eee;">
                <div class="col-8" :style="{'padding-left': articleInfo.padding }">{{ articleInfo.article.name }}</div>
                <div class="col-2 text-center">{{ articleInfo.amount }}</div>
                <div class="col-2">{{ formatNumberAsMoney(articleInfo.price*0.01) }}</div>
            </div>
        </div>

    </modal>
</template>

<script setup>
    import Article from '@/models/article';
    import articleService from '@/services/http/article-service';
    import { ref } from 'vue';
    import UsePriceFunctions from './UsePriceFunctions';

    const props = defineProps(['articleId']);

    const { formatNumberAsMoney } = UsePriceFunctions()

    const article = ref(new Article({}));
    const flattenedTree = ref([]);

    const getArticle = function() {
        return articleService.getById(props.articleId, {
            include: ['childArticlesTree']
        }).then(articleModel => {
            article.value = articleModel;
            flattenedTree.value = getTreeFlattened(article.value.childArticlesTree);
        });
    }
    getArticle();

    const getTreeFlattened = function(tree, level = 1) {
        let arr = [];

        for (const article of tree) {
            arr.push({
                article: article,
                padding: level*15 + 'px',
                amount: article.pivot.amount,
                price: (article.name.includes('zonder Blad') ? 0.001 : 1 ) * article.pivot.amount * article.cents
            });

            if (article.childArticlesTree.count() > 0) {
                arr = [...arr, ...getTreeFlattened(article.childArticlesTree, level+1)]
            }
        }

        return arr;
    }

</script>
