<template>
    <SideModal @close="close()">
        <template v-slot:header>
            <span>Log</span>
        </template>


        <div class="log" style="margin-bottom: 20px">
            <h4>Gebruikers start</h4>
            <div class="row">
                <div class="col-4">
                    Naam
                </div>
                <div class="col-1">
                    Start
                </div>
                <div class="col-1">
                    Laatst
                </div>
                <div class="col-5">
                    Oogstopdracht
                </div>
            </div>
            <div class="row" v-for="userStartLog in startLog">
                <div class="col-4">
                    {{ userStartLog.name }}({{ userStartLog.initials }})
                </div>
                <div class="col-1">
                    {{ userStartLog.started }}
                </div>
                <div class="col-1">
                    {{ userStartLog.busyUntil }}
                </div>
                <div class="col-6">
                    {{ harvestAssignments.firstWhere('id', userStartLog.harvest_assignment_id).name() }}
                </div>
            </div>
        </div>

        <div class="log">
            <div style="margin-bottom: 20px" v-for="harvestAssignmentLog in log">
                <h4>{{ harvestAssignmentLog.harvestAssignment.name() }}</h4>
                <div class="row">
                    <div class="col-5">
                        Tijd &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Gebruikers
                    </div>
                    <div class="col-3">
                        Geoogst
                    </div>
                    <div class="col-4">
                        Opmerkingen
                    </div>
                </div>
                <div v-for="logRow in harvestAssignmentLog.data" class="row">
                    <div class="col-5">
                        {{ logRow.from }} - {{ logRow.till }} &nbsp;&nbsp;&nbsp;
                        {{ logRow.users.map(u => u.initials).join(', ') }}
                    </div>
                    <div class="col-3">
                        {{ Math.round(logRow.harvestedBefore) }} -> {{ Math.round(logRow.harvestedAfter) }}
                    </div>
                    <div class="col-4">
                        <div v-for="logItem in logRow.changeReason">{{ logItem }}</div>
                    </div>
                </div>
            </div>
        </div>
    </SideModal>
</template>
<script setup>
    import { ref } from 'vue';
    import SideModal from './SideModal.vue';

    // harvested
    // users
    // from
    // till
    // changeReason
    const emit = defineEmits(['close'])
    const props = defineProps(['log', 'startLog', 'harvestAssignments', 'locations'])

    const close = function() {
        emit('close');
        document.querySelector('body').classList = '';
    };

</script>
