import { AbstractModel } from './abstract-model.js';
import Type from './type';

export default class ArticleGroup extends AbstractModel {

    getMutators()
    {
        return {
        }
    }

    relations() {
        return {
            type: Type
        }
    }

    relationPlural()
    {
        return {
            type: false
        }
    }

}
